import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { defaultMessage: 'How much would you like to deposit?' },
  description: {
    defaultMessage:
      'Please enter an amount you would like to deposit. The maximum amount you can deposit is shown below.',
  },
  descriptionWithMin: {
    defaultMessage:
      'Please enter an amount you would like to deposit. The range of minimum / maximum deposits are shown below.',
  },
  stake: { defaultMessage: 'stake' },

  amountTitle: { defaultMessage: 'Available to deposit' },

  notEnoughTitle: {
    defaultMessage: 'Your balance is not enough to meet minimum deposit requirement',
  },
  notEnoughDescription: {
    defaultMessage:
      'Your balance of {currencySymbol} is not enough to meet the minimum deposit requirement of {minimum}. Transfer {currencySymbol} to your wallet to be able to deposit',
  },
  notEnoughLPTokenDescription: {
    defaultMessage: `Your balance of {currencySymbol} is not enough to meet the minimum deposit requirement of {minimum}. Transfer {currencySymbol} to your wallet in order to deposit. To get {currencySymbol}, you need to provide liquidity to the correct pool.`,
  },
  noDataTitle: { defaultMessage: 'Your balance is zero' },
  noDataDescription: {
    defaultMessage:
      'Your balance of {currencySymbol} is 0. Transfer {currencySymbol} to your wallet to be able to deposit',
  },

  exceedLimitTitle: { defaultMessage: 'Deposit limit is reached' },

  viewPool: { defaultMessage: 'View Pool' },

  connectWallet: { defaultMessage: 'Please connect a wallet' },
  connectWalletDescription: {
    defaultMessage:
      'We couldn’t detect a wallet. Connect a wallet to deposit and see your balance grow.',
  },

  warningText: {
    defaultMessage:
      'Before depositing {symbol} please check that the amount you want to deposit is not currently being used for staking. If it is being used for staking, your transaction might fail.',
  },

  stakingView: { defaultMessage: 'staking view' },
});

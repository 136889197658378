import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { defaultMessage: 'Something went wrong' },
  description: {
    defaultMessage: 'Please report the problem to our developer team so they can look into it.',
  },
  buttonTitle: { defaultMessage: 'Report' },
  reload: { defaultMessage: 'Reload' },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { defaultMessage: 'How much would you like to borrow?' },
  description: {
    defaultMessage:
      'Please enter an amount you would like to borrow. The maximum amount you can borrow is shown below.',
  },
  amountTitle: { defaultMessage: 'Available to borrow' },

  noDataTitle: { defaultMessage: 'No deposits yet' },
  noDataDescription: {
    defaultMessage: 'You need to deposit some collateral first to unlock your borrowing power.',
  },
  noLiquidityAvailableTitle: { defaultMessage: 'No liquidity' },
  noLiquidityAvailableDescription: {
    defaultMessage: 'There is no {symbol} available liquidity to borrow.',
  },
  healthFactorTooLowTitle: { defaultMessage: 'Health factor too low' },
  healthFactorTooLowDescription: {
    defaultMessage:
      'Deposit more collateral or repay part of your borrowings to increase your health factor and be able to borrow.',
  },
  noDataButtonTitle: { defaultMessage: 'Deposit now' },
  exceedLimitTitle: { defaultMessage: 'Borrowing limit is reached' },

  connectWallet: { defaultMessage: 'Please connect a wallet' },
  connectWalletDescription: {
    defaultMessage: 'We couldn’t detect a wallet. Connect a wallet to borrow.',
  },
});

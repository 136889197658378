import { useIntl } from 'react-intl';

import InfoWrapper from '../../../../components/wrappers/InfoWrapper';
import InfoPanel from '../../../../components/InfoPanel';
import Link from '../../../../components/basic/Link';

import messages from './messages';
import staticStyles from './style';

export default function ClaimInfoPanel() {
  const intl = useIntl();

  return (
    <div className="ClaimInfoPanelWrapper">
      <InfoWrapper>
        <InfoPanel>
          <div className="ClaimInfoPanel">
            <p>
              {intl.formatMessage(messages.title, {
                dashboard: (
                  <Link
                    to={'/dashboard'}
                    title={intl.formatMessage(messages.dashboard)}
                    color="secondary"
                    absolute={false}
                    inNewWindow={false}
                  />
                ),
              })}
            </p>

            <style jsx={true} global={true}>
              {staticStyles}
            </style>
          </div>
        </InfoPanel>
      </InfoWrapper>
    </div>
  );
}

import { valueToBigNumber } from '@aave/math-utils';
import BigNumber from 'bignumber.js';
import { ComputedReserveData, ComputedUserReserveWithLimits } from '../libs/pool-data-provider';

export function getMaxAmountToDeposit(
  walletBalance: BigNumber,
  reserve: ComputedReserveData,
  userReserve: ComputedUserReserveWithLimits | undefined
): BigNumber {
  let maxAmountToDeposit = walletBalance;
  if (
    reserve.hasMaxGlobalDepositSizeLimit &&
    maxAmountToDeposit.gt(valueToBigNumber(reserve.globalDepositSizeAvailable))
  ) {
    maxAmountToDeposit = valueToBigNumber(reserve.globalDepositSizeAvailable);
  }
  if (
    reserve.hasMaxIndividualDepositSizeLimit &&
    userReserve &&
    maxAmountToDeposit.gt(valueToBigNumber(userReserve.individualDepositSizeAvailable))
  ) {
    maxAmountToDeposit = valueToBigNumber(userReserve.individualDepositSizeAvailable);
  }
  return maxAmountToDeposit;
}

export function getMaxAmountToBorrow(
  availableBorrows: BigNumber,
  reserve: ComputedReserveData,
  userReserve: ComputedUserReserveWithLimits | undefined
): BigNumber {
  let maxAmountToBorrow = availableBorrows;
  if (
    reserve.hasMaxGlobalBorrowSizeLimit &&
    maxAmountToBorrow.gt(valueToBigNumber(reserve.globalBorrowSizeAvailable))
  ) {
    maxAmountToBorrow = valueToBigNumber(reserve.globalBorrowSizeAvailable);
  }
  if (
    reserve.hasMaxIndividualBorrowSizeLimit &&
    userReserve &&
    maxAmountToBorrow.gt(valueToBigNumber(userReserve.individualBorrowSizeAvailable))
  ) {
    maxAmountToBorrow = valueToBigNumber(userReserve.individualBorrowSizeAvailable);
  }
  if (maxAmountToBorrow.lte(0)) {
    maxAmountToBorrow = valueToBigNumber('0');
  }
  return maxAmountToBorrow;
}

import React from 'react';
import ReactTooltip from 'react-tooltip';

import { useThemeContext } from '@aave/aave-ui-kit';

import TableItem from '../../../../components/BasicAssetsTable/TableItem';
import TableColumn from '../../../../components/BasicTable/TableColumn';
import Value from '../../../../components/basic/Value';
import LiquidityMiningCard from '../../../../components/liquidityMining/LiquidityMiningCard';
import NoData from '../../../../components/basic/NoData';
import { isAssetStable } from '../../../../helpers/config/assets-config';

import { BorrowTableItem } from './types';
import staticStyles from './style';

export default function BorrowItem({
  id,
  symbol,
  underlyingAsset,
  availableBorrows,
  availableBorrowsInUSD,
  marketLimitTooltip,
  stableBorrowRate,
  variableBorrowRate,
  avg30DaysVariableRate,
  stableBorrowRateEnabled,
  userId,
  isFreezed,
  vincentivesAPR,
  sincentivesAPR,
}: BorrowTableItem) {
  const { currentTheme } = useThemeContext();
  const url = `/borrow/${underlyingAsset}-${id}`;

  return (
    <TableItem
      symbol={symbol}
      url={url}
      isFreezed={isFreezed}
      isBorrow={true}
      darkOnDarkMode={true}
    >
      <TableColumn>
        {!userId || Number(availableBorrows) <= 0 ? (
          <>
            <p data-tip={true} data-for={`available-to-borrow-${symbol}`}>
              <NoData color="dark" />
            </p>
            <ReactTooltip
              className="BorrowItem__noDataTooltip"
              id={`available-to-borrow-${symbol}`}
              effect="solid"
            >
              {marketLimitTooltip}
            </ReactTooltip>
          </>
        ) : (
          <Value
            value={Number(availableBorrows)}
            subValue={availableBorrowsInUSD}
            subSymbol="USD"
            maximumSubValueDecimals={2}
            minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            tooltipId={`available-to-borrow-${symbol}`}
            additionalTooltip={marketLimitTooltip}
            className="BorrowItem__value"
          />
        )}
      </TableColumn>

      {!isFreezed && (
        <TableColumn>
          <LiquidityMiningCard
            value={variableBorrowRate}
            thirtyDaysValue={avg30DaysVariableRate}
            liquidityMiningValue={vincentivesAPR}
            symbol={symbol}
            type="borrow-variable"
          />
        </TableColumn>
      )}

      {!isFreezed && (
        <TableColumn>
          {stableBorrowRateEnabled ? (
            <LiquidityMiningCard
              value={stableBorrowRate}
              liquidityMiningValue={sincentivesAPR}
              symbol={symbol}
              type="borrow-stable"
            />
          ) : (
            <NoData color="dark" />
          )}
        </TableColumn>
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .BorrowItem__noDataTooltip {
          background: ${currentTheme.darkBlue.hex} !important;
          &:after {
            border-top-color: ${currentTheme.darkBlue.hex} !important;
          }
        }
      `}</style>
    </TableItem>
  );
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  cantConnect: { defaultMessage: "Can't connect with Ledger device" },
  firstCheck: { defaultMessage: 'Check that you entered the correct PIN' },
  secondCheck: { defaultMessage: 'Check that you selected the Ethereum app' },
  thirdCheck: { defaultMessage: 'Update to the latest version of the Ethereum app' },
  fourthCheck: { defaultMessage: 'Check that you have ‘contract data’ enabled' },
  more: { defaultMessage: 'More information' },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  yourDeposits: { defaultMessage: 'Your deposits' },
  secondTableColumnTitle: { defaultMessage: 'Current balance' },
  collateral: { defaultMessage: 'Collateral' },

  currentBalanceUSDRowTitle: { defaultMessage: 'Current balance in USD' },
  thirtyDaysAverageTitle: { defaultMessage: '30 Days Average' },

  valueRowTitle: { defaultMessage: 'Earned' },
  apyRowTitle: { defaultMessage: 'APY' },
  useAsCollateralRowTitle: { defaultMessage: 'Use as collateral' },

  withdrawYourDeposit: { defaultMessage: 'Withdraw your deposit' },
  swapYourDeposit: { defaultMessage: 'Swap your deposit' },
  depositMore: { defaultMessage: 'Deposit more' },

  offLabel: { defaultMessage: 'No' },
  onLabel: { defaultMessage: 'Yes' },
});

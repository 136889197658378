import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { constants } from 'ethers';
import BigNumber from 'bignumber.js';
import queryString from 'query-string';
import {
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  transactionType,
} from '@aave/contract-helpers';

import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { useStakeDataContext } from '../../../../libs/pool-data-provider/hooks/use-stake-data-context';
import { PhiatFeeDistribution__factory } from '../../../../libs/pool-data-provider/contracts/factories/PhiatFeeDistribution__factory';
import { getProvider } from '../../../../helpers/config/markets-and-network-config';

import Row from '../../../../components/basic/Row';
import StakeTxConfirmationView from '../../components/StakeTxConfirmationView';
import Value from '../../../../components/basic/Value';
import messages from './messages';

export default function StakeWithApprovalConfirmation() {
  const intl = useIntl();
  const location = useLocation();
  const { userId } = useStaticPoolDataContext();
  const { chainId, stakeConfig, stakeData, stakingService } = useStakeDataContext();

  const query = queryString.parse(location.search);
  let amount = new BigNumber(typeof query.amount === 'string' ? query.amount : 0);

  if (amount.eq(0) || !userId) {
    return null;
  }

  let blockingError = '';
  const userWalletBalance = new BigNumber(stakeData.userWalletBalance);
  if (amount.gt(userWalletBalance)) {
    blockingError = intl.formatMessage(messages.notEnoughBalance, {
      asset: stakeConfig.stakingTokenSymbol,
    });
  }

  const convertedAmount = amount
    .multipliedBy(new BigNumber(10).exponentiatedBy(stakeData.stakingTokenPrecision))
    .toFixed(0);
  const handleGetTransactions = async (): Promise<EthereumTransactionTypeExtended[]> => {
    if (convertedAmount === '0') return [];

    const txs: EthereumTransactionTypeExtended[] = [];
    // check for approval
    const { isApproved, approve } = stakingService.erc20Service;
    const approved: boolean = await isApproved({
      token: stakeConfig.stakingToken,
      user: userId,
      spender: stakeConfig.feeDistribution,
      amount: convertedAmount,
    });
    if (!approved) {
      const approveTx = approve({
        user: userId,
        token: stakeConfig.stakingToken,
        spender: stakeConfig.feeDistribution,
        amount: constants.MaxUint256.toString(),
      });
      txs.push(approveTx);
    }
    // stake
    const stakingContract = PhiatFeeDistribution__factory.connect(
      stakeConfig.feeDistribution,
      getProvider(chainId)
    );
    const txCallback: () => Promise<transactionType> = stakingService.generateTxCallback({
      rawTxMethod: async () => stakingContract.populateTransaction.stake(convertedAmount),
      from: userId,
      gasSurplus: 20,
    });
    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.STAKE_ACTION,
      gas: stakingService.generateTxPriceEstimation(txs, txCallback),
    });

    return txs;
  };

  return (
    <StakeTxConfirmationView
      caption={intl.formatMessage(messages.title)}
      description={intl.formatMessage(messages.description, {
        asset: <strong>{stakeConfig.stakingTokenSymbol}</strong>,
      })}
      getTransactionsData={handleGetTransactions}
      boxTitle={intl.formatMessage(messages.stake, { asset: stakeConfig.stakingTokenSymbol })}
      boxDescription={intl.formatMessage(messages.boxDescription)}
      mainTxName={intl.formatMessage(messages.stake, { asset: stakeConfig.stakingTokenSymbol })}
      mainTxType="STAKE_ACTION"
      blockingError={blockingError}
      goToAfterSuccess="/staking"
      successButtonTitle={intl.formatMessage(messages.backToStaking)}
      buttonTitle={intl.formatMessage(messages.buttonTitle)}
    >
      <Row
        title={intl.formatMessage(messages.stake, {
          asset: stakeConfig.stakingTokenSymbol,
        })}
      >
        <Value
          symbol={stakeConfig.stakingTokenSymbol}
          value={amount.toString()}
          tokenIcon={true}
          tooltipId={stakeConfig.stakingTokenSymbol}
        />
      </Row>
    </StakeTxConfirmationView>
  );
}

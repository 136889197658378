import { defineMessages } from 'react-intl';

export default defineMessages({
  deposit: { defaultMessage: 'Deposit' },
  borrowed: { defaultMessage: 'Borrowed' },
  withdrawal: { defaultMessage: 'Withdrawal' },
  repayment: { defaultMessage: 'Repayment' },
  changeOfAPYType: { defaultMessage: 'Change of APY Type' },
  changeOfUsageAsCollateral: { defaultMessage: 'Change of usage as collateral' },
  liquidation: { defaultMessage: 'Liquidation' },

  staked: { defaultMessage: 'Staked' },
  unstaked: { defaultMessage: 'Unstaked' },
  unstakeCancelled: { defaultMessage: 'Unstake Cancelled' },
  withdrawn: { defaultMessage: 'Withdrawn' },

  download: { defaultMessage: 'Download .CSV' },
});

import { Navigation } from '../../components/menu/navigation';
import messages from './messages';

export const moreMenuItems: Navigation[] = [
  {
    link: 'https://t.me/+_2BI2i6MFtg1N2I9',
    title: messages.tele,
    absolute: true,
  },
  {
    link: 'https://twitter.com/phiatcrypto',
    title: messages.twitter,
    absolute: true,
  },
  {
    link: 'https://phatty.io',
    title: messages.phatty,
    absolute: true,
  },
  {
    link: 'https://phux.io',
    title: messages.phux,
    absolute: true,
  },
  {
    link: 'https://phame.io',
    title: messages.phame,
    absolute: true,
  },
];

export const moreMenuExtraItems: Navigation[] = [];

export const moreMenuMobileOnlyItems: Navigation[] = [];

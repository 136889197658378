import { defineMessages } from 'react-intl';

export default defineMessages({
  asset: { defaultMessage: 'Asset' },
  stableAPY: { defaultMessage: 'Stable APY' },
  variableAPY: { defaultMessage: 'Variable APY' },
  availableToBorrow: { defaultMessage: 'Available to borrow' },
  basedCollateral: { defaultMessage: 'Based on your collateral' },
  dividerTitle: { defaultMessage: 'To borrow, deposit any of these assets into your wallet.' },

  connectWallet: { defaultMessage: 'To borrow, please connect a wallet' },
});

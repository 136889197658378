import React from 'react';
import { useIntl } from 'react-intl';

import { rgba, useThemeContext } from '@aave/aave-ui-kit';
import Link from '../../../../components/basic/Link';
import TailArrow from '../../../../components/basic/TailArrow';

import messages from './messages';
import staticStyles from './style';
import { useStakeDataContext } from '../../../../libs/pool-data-provider/hooks/use-stake-data-context';

export default function TextPurchaseLink() {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { stakeConfig } = useStakeDataContext();

  const background = rgba(`${currentTheme.textDarkBlue.rgb}, 0.03`);

  return (
    <div className="TextPurchaseLink">
      <Link
        to={stakeConfig.stakingTokenDexUrl}
        className="TextPurchaseLink__link ButtonLink"
        absolute={true}
        inNewWindow={true}
        color="dark"
      >
        <span>
          {intl.formatMessage(messages.title, {
            PHIAT: <strong>{stakeConfig.stakingTokenSymbol}</strong>,
            DEX: stakeConfig.stakingTokenDex,
          })}
        </span>
        <TailArrow className="TextPurchaseLink__arrow" type="left" color="dark" />
      </Link>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .TextPurchaseLink {
          .TextPurchaseLink__link {
            background: ${background};
          }
        }
      `}</style>
    </div>
  );
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  apyRowTitle: { defaultMessage: 'APY' },
  changeAPYRowTitle: { defaultMessage: 'Change APY Type' },

  yourBorrows: { defaultMessage: 'Your borrows' },
  secondTableColumnTitle: { defaultMessage: 'Borrowed' },
  fourthTableColumnTitle: { defaultMessage: 'APY Type' },
  thirtyDaysAverageTitle: { defaultMessage: '30 Days Average' },

  borrowMore: { defaultMessage: 'Borrow more' },
  repayYourBorrow: { defaultMessage: 'Repay your borrow' },

  offLabel: { defaultMessage: 'Stable' },
  onLabel: { defaultMessage: 'Variable' },
});

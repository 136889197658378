import { defineMessages } from 'react-intl';

export default defineMessages({
  txName: { defaultMessage: `Switch Interest Type` },
  pageTitle: { defaultMessage: `Switch {currencySymbol} Interest Type` },

  errorNotBorrowYetUsingThisCurrency: {
    defaultMessage: 'You have not borrow yet using this currency',
  },
  errorStableInterestTypeIsDisabled: {
    defaultMessage: 'Stable Interest Type is disabled for this currency',
  },
  errorYouCantBorrowStableNow: {
    defaultMessage:
      "You can't change Interest Type to stable as your borrowings are higher than your collateral",
  },
  caption: { defaultMessage: `Switch Interest Type to {rateModeAfterSwitch}` },
  boxTitle: { defaultMessage: 'Switch of Interest Type' },
  boxDescription: {
    defaultMessage: 'Please submit to switch Interest Type to {rateModeAfterSwitch}',
  },
  currentBorrowRateTitle: { defaultMessage: 'Current {borrowRateMode} rate' },
  nextBorrowRateMode: { defaultMessage: 'Next {borrowRateMode} rate' },

  currency: { defaultMessage: 'Currency' },

  connectWallet: { defaultMessage: 'Please connect a wallet' },
  connectWalletDescription: {
    defaultMessage: 'We couldn’t detect a wallet. Connect a wallet to switch interest type.',
  },

  buttonTitle: { defaultMessage: 'Submit' },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  txName: { defaultMessage: 'Usage as collateral' },
  pageTitleMobile: { defaultMessage: `{currencySymbol}` },
  pageTitleFirst: { defaultMessage: `Use {currencySymbol} as collateral` },
  pageTitleSecond: { defaultMessage: `Do not use {currencySymbol} as collateral` },

  errorDoNotHaveDepositsInThisCurrency: {
    defaultMessage: "You don't have deposits in this currency",
  },
  errorCanNotUseThisCurrencyAsCollateral: {
    defaultMessage: "You can't use this currency as collateral",
  },
  errorCanNotSwitchUsageAsCollateralMode: {
    defaultMessage:
      "You can't switch usage as collateral mode for this currency, because it will cause collateral call",
  },
  firstCaption: { defaultMessage: 'Use {currencySymbol} as collateral' },
  secondCaption: { defaultMessage: 'Do not use {currencySymbol} as collateral' },
  boxTitle: { defaultMessage: 'Usage as collateral' },
  boxDescriptionNotUse: {
    defaultMessage: 'Please submit not to use {currencySymbol} as collateral',
  },
  boxDescriptionUse: { defaultMessage: 'Please submit to use {currencySymbol} as collateral' },
  rowTitle: { defaultMessage: 'Currency' },
  currentHealthFactor: { defaultMessage: 'Current Health Factor' },
  nextHealthFactor: { defaultMessage: 'Next Health Factor' },

  connectWallet: { defaultMessage: 'Please connect a wallet' },
  connectWalletDescription: { defaultMessage: 'We couldn’t detect a wallet.' },

  buttonTitle: { defaultMessage: 'Submit' },
});

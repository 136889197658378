import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .AgreementWarningModal {
    &__content {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
      align-item: left;
      max-width: 825px;
      position: relative;
      z-index: 3;
      @include respond-to(xl) {
        grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
        max-width: 660px;
      }
      @include respond-to(md) {
        display: flex;
        flex-direction: column;
        max-width: 540px;
      }
    }

    &__inner-button {
      width: 100%;
      font-size: $large;
    }
  }
`;

export default staticStyles;

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { defaultMessage: 'Your transaction overview' },
  description: {
    defaultMessage:
      'These are your transaction details. Make sure to check if this is correct before submitting.',
  },
  withdraw: { defaultMessage: 'Withdraw' },
  withdrawAsset: { defaultMessage: 'Withdraw {asset}' },
  boxDescription: { defaultMessage: 'Please submit to withdraw' },
  backToStaking: { defaultMessage: 'Back to staking' },

  blockingError: { defaultMessage: 'Can not withdraw' },
});

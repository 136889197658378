import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .StakingClaim {
    &__titleRow {
      margin-bottom: 30px;
      @include respond-to(xl) {
        margin-bottom: 18px;
      }
      @include respond-to(lg) {
        margin-bottom: 15px;
      }
      @include respond-to(md) {
        margin-bottom: 18px;
      }
    }
    &__rewardRow {
      margin-bottom: 10px;
      @include respond-to(xl) {
        margin-bottom: 6px;
      }
      @include respond-to(lg) {
        margin-bottom: 5px;
      }
      @include respond-to(md) {
        margin-bottom: 6px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__valueRow {
      margin-top: 30px;
      @include respond-to(xl) {
        margin-top: 18px;
      }
      @include respond-to(lg) {
        margin-top: 15px;
      }
      @include respond-to(md) {
        margin-top: 18px;
      }
    }
  }
`;

export default staticStyles;

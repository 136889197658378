import { defineMessages } from 'react-intl';

export default defineMessages({
  errorWalletBalanceNotEnough: {
    defaultMessage: 'Your {poolReserveSymbol} wallet balance is not enough',
  },
  caption: { defaultMessage: 'Deposit overview' },
  boxDescription: { defaultMessage: 'Please submit to deposit' },
  approveDescription: { defaultMessage: 'Please approve before depositing' },
  valueRowTitle: { defaultMessage: 'Amount' },
  newHealthFactor: { defaultMessage: 'New health factor' },

  connectWallet: { defaultMessage: 'Please connect a wallet' },
  connectWalletDescription: {
    defaultMessage:
      'We couldn’t detect a wallet. Connect a wallet to deposit and see your balance grow.',
  },
  collateral: { defaultMessage: 'Collateral Usage' },
  yes: { defaultMessage: 'Yes' },
  no: { defaultMessage: 'No' },
});

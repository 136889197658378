import { defineMessages } from 'react-intl';

export default defineMessages({
  errorTitle: { defaultMessage: 'You are connected to the wrong network' },
  errorDescription: { defaultMessage: 'Please change your network to one of: {networks}' },
  unsupportedNetwork: { defaultMessage: 'Unsupported network, use one of: {supportedNetworks}' },
  unsupportedNetworkSingle: {
    defaultMessage: 'Change your current network to {networkName} in the wallet app to proceed.',
  },
  ledgerDisconnected: {
    defaultMessage:
      'Ledger device is disconnected or locked. Try again to plug and unlock your Ledger device.',
  },
});

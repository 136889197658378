export const defaultTheme = {
  pink: [230, 25, 230],
  purple: [128, 0, 255],
  green: [0, 255, 85],
  gradientBackground: `linear-gradient(
    120deg,
    rgba(230, 25, 230, 1) 0%,
    rgba(128, 0, 255, 1) 50%,
    rgba(0, 255, 85, 1) 100%
  )`,
  rotatingGradientBackground: `linear-gradient(
    90deg,
    rgba(230, 25, 230, 1),
    rgba(128, 0, 255, 1),
    rgba(0, 255, 85, 1),
    rgba(230, 25, 230, 1)
  )`,
  gradientText: `linear-gradient(
    120deg,
    rgba(230, 25, 230, 1) 0%,
    rgba(128, 0, 255, 1) 75%
  )`,
};

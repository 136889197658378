import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .DepositItem__value {
    .Value {
      &__tooltip {
        max-width: 500px;
        white-space: normal !important;
        @include respond-to(xl) {
          max-width: 300px;
        }

        p {
          font-weight: 300;
          &:first-of-type {
            margin-top: 10px;
          }
          margin-top: 4px;
        }
      }
    }
  }

  .DepositItem__noDataTooltip {
    padding: 5px 10px;
    font-size: $medium;
    @include respond-to(xl) {
      font-size: $small;
    }

    max-width: 500px;
    white-space: normal !important;
    @include respond-to(xl) {
      max-width: 300px;
    }

    p {
      font-weight: 300;
      &:first-of-type {
        margin-top: 10px;
      }
      margin-top: 4px;
    }
  }
`;

export default staticStyles;

import { defineMessages } from 'react-intl';

export default defineMessages({
  caption: { defaultMessage: 'Change network to {networkName}' },
  description: { defaultMessage: 'You’re currently connected to {networkName}. {additional}' },
  additionalDescription: {
    defaultMessage: 'Change your current network in the wallet app to proceed.',
  },
  networkIsNotSupportedCaption: { defaultMessage: 'Please connect another wallet' },
  networkIsNotSupportedDescription: {
    defaultMessage: "Sorry, we don't support {networkName} on {walletName}",
  },
  changeNetwork: { defaultMessage: 'Change network' },

  howToChange: { defaultMessage: 'How to change to' },
  goBack: { defaultMessage: 'Go back' },
});

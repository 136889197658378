import { defineMessages } from 'react-intl';

export default defineMessages({
  optionTitleLeft: { defaultMessage: 'All' },
  optionTitleRight: { defaultMessage: 'Stable Coins' },
  noDataText: { defaultMessage: 'There are no currencies matching the parameters' },

  availableToBorrow: { defaultMessage: 'Available to borrow' },
  myBorrows: { defaultMessage: 'My borrows' },
});

import React, { ReactNode, useContext } from 'react';
import { normalize, normalizeBN, valueToBigNumber } from '@aave/protocol-js';

import Preloader from '../../../components/basic/Preloader';
import ErrorPage from '../../../components/ErrorPage';
import { useProtocolDataContext } from '../../protocol-data-provider';
import { useStaticPoolDataContext } from '../providers/static-pool-data-provider';
import { ComputedStakeData, StakeData, StakeSeaCreature } from '../types/stake';
import { useStakeDataWithRpc } from './use-stake-data-with-rpc';
import { ConnectionMode, useConnectionStatusContext } from '../../connection-status-provider';
import { CustomChainId } from '../../../ui-config/networks';
import { StakeConfig, stakeConfig } from '../../../ui-config/stake';
import { StakingService } from '@aave/contract-helpers';
import { getProvider } from '../../../helpers/config/markets-and-network-config';
import {
  ComputedReserveData,
  useDynamicPoolDataContext,
} from '../providers/dynamic-pool-data-provider';
import { assetsOrder } from '../../../ui-config/assets';

export function computeStakeData(
  data: StakeData,
  marketRefPriceInUsd: string,
  reserves: ComputedReserveData[]
): ComputedStakeData {
  const stakingTokenPrecision = data.data
    ? data.data.stakingTokenPrecision.toString().length - 1
    : 18;

  let userStakedPct = '';
  const userSeaCreature: StakeSeaCreature = {
    icon: '',
    name: '',
  };
  if (data.data && data.userData) {
    const pct = normalizeBN(
      data.userData.stakedBalance.mul(1e10).div(data.data.totalSupply).toString(),
      8
    ).toNumber();
    if (pct <= 0.000001) {
      userSeaCreature.icon = '🐚';
      userSeaCreature.name = 'Shell';
      userStakedPct = valueToBigNumber(pct).toFixed(7);
    } else if (pct <= 0.00001) {
      userSeaCreature.icon = '🦐';
      userSeaCreature.name = 'Shrimp';
      userStakedPct = valueToBigNumber(pct).toFixed(6);
    } else if (pct <= 0.0001) {
      userSeaCreature.icon = '🦀';
      userSeaCreature.name = 'Crab';
      userStakedPct = valueToBigNumber(pct).toFixed(5);
    } else if (pct <= 0.001) {
      userSeaCreature.icon = '🐢';
      userSeaCreature.name = 'Turtle';
      userStakedPct = valueToBigNumber(pct).toFixed(4);
    } else if (pct <= 0.01) {
      userSeaCreature.icon = '🦑';
      userSeaCreature.name = 'Squid';
      userStakedPct = valueToBigNumber(pct).toFixed(3);
    } else if (pct <= 0.1) {
      userSeaCreature.icon = '🐬';
      userSeaCreature.name = 'Dolphin';
      userStakedPct = valueToBigNumber(pct).toFixed(2);
    } else if (pct <= 1) {
      userSeaCreature.icon = '🦈';
      userSeaCreature.name = 'Shark';
      userStakedPct = valueToBigNumber(pct).toFixed(2);
    } else if (pct <= 10) {
      userSeaCreature.icon = '🐋';
      userSeaCreature.name = 'Whale';
      userStakedPct = valueToBigNumber(pct).toFixed(2);
    } else {
      userSeaCreature.icon = '🔱';
      userSeaCreature.name = 'Poseidon';
      userStakedPct = valueToBigNumber(pct).toFixed(2);
    }
  }

  const rewards: {
    tokenSymbol: string;
    amount: string;
  }[] = [];
  if (data.userData) {
    data.userData.claimableRewards.forEach(({ token, amount }) => {
      for (const reserve of reserves) {
        if (reserve.aTokenAddress === token) {
          rewards.push({
            tokenSymbol: reserve.symbol,
            amount: normalize(amount.toString(), reserve.decimals),
          });
          break;
        }
      }
      if (token === data.stakeConfig.phuxToken) {
        rewards.push({
          tokenSymbol: 'PHUX',
          amount: normalize(amount.toString(), 18),
        });
      }
    });
  }
  let totalRewardsInUsd = valueToBigNumber('0');
  let phuxUsdPrice: string | undefined;
  if (data.data && data.userData) {
    const price: { [tokenSymbol: string]: string } = {};
    for (const reserve of reserves) {
      price[reserve.symbol] = reserve.priceInMarketReferenceCurrency;
    }
    let phuxAmount = valueToBigNumber('0');
    for (const { tokenSymbol, amount } of rewards) {
      if (tokenSymbol === 'PHUX') {
        phuxAmount = phuxAmount.plus(amount);
      } else {
        totalRewardsInUsd = totalRewardsInUsd.plus(
          valueToBigNumber(amount).multipliedBy(price[tokenSymbol])
        );
      }
    }
    phuxUsdPrice = normalize(data.data.phuxUsdPrice.toString(), 18);
    const phuxInUsd = phuxAmount.multipliedBy(phuxUsdPrice);
    totalRewardsInUsd = totalRewardsInUsd.multipliedBy(marketRefPriceInUsd).plus(phuxInUsd);
  }

  return {
    stakingTokenPrecision: stakingTokenPrecision,
    totalSupply: data.data
      ? normalize(data.data.totalSupply.toString(), stakingTokenPrecision)
      : '55,555,000.00',
    totalStakedSupply: data.data
      ? normalize(data.data.totalStakedSupply.toString(), stakingTokenPrecision)
      : '-',
    totalStakedPercentage: data.data
      ? normalize(data.data.totalStakedSupply.mul(1e4).div(data.data.totalSupply).toString(), 2)
      : '-',
    stakeAPR: data.data
      ? normalize(
          normalizeBN(data.data.rewardDurationReturn.toString(), 18)
            .multipliedBy(3600 * 24 * 365)
            .dividedBy(valueToBigNumber(data.data.rewardDuration.toString())),
          0
        )
      : '0',
    stakeUnlockDays: data.data ? data.data.unstakeDuration.toNumber() / 24 / 3600 : 14,
    stakeWithdrawDays: data.data ? data.data.withdrawDuration.toNumber() / 24 / 3600 : 7,

    userWalletBalance: data.userData
      ? normalize(data.userData.walletBalance.toString(), stakingTokenPrecision)
      : '0',
    userStakedBalance: data.userData
      ? normalize(data.userData.stakedBalance.toString(), stakingTokenPrecision)
      : '0',
    userStakedPct: userStakedPct,
    userSeaCreature: userSeaCreature,
    userUnstakedBalance: data.userData
      ? normalize(data.userData.unstakedBalance.toString(), stakingTokenPrecision)
      : '0',
    userWithdrawableBalance: data.userData
      ? normalize(data.userData.withdrawableBalance.toString(), stakingTokenPrecision)
      : '0',
    userCanClaim: data.userData
      ? data.userData.claimableRewards.some(({ amount }) => amount.gt(0))
      : false,
    userCanUnstake: data.userData
      ? data.userData.stakedBalance.gt(0) &&
        data.userData.unstakedBalance.eq(0) &&
        data.userData.withdrawableBalance.eq(0)
      : false,
    userCanCancel: data.userData ? data.userData.unstakedBalance.gt(0) : false,
    userCanWithdraw: data.userData ? data.userData.withdrawableBalance.gt(0) : false,
    userWithdrawTimestamp: data.userData ? data.userData.withdrawTimestamp.toNumber() : 0,
    userExpirationTimestamp: data.userData ? data.userData.expirationTimestamp.toNumber() : 0,
    userRewards: rewards.sort(
      (a, b) =>
        assetsOrder.indexOf(a.tokenSymbol.toUpperCase()) -
        assetsOrder.indexOf(b.tokenSymbol.toUpperCase())
    ),
    phuxUsdPrice: phuxUsdPrice,
    userTotalRewardsInUsd: totalRewardsInUsd.toFixed(10),
  };
}

const StakeDataContext = React.createContext<{
  chainId: CustomChainId;
  stakeConfig: StakeConfig;
  stakeData: ComputedStakeData;
  stakingService: StakingService;
  refresh: () => void;
}>({
  chainId: {} as CustomChainId,
  stakeConfig: {} as StakeConfig,
  stakeData: {} as ComputedStakeData,
  stakingService: {} as StakingService,
  refresh: () => {},
});

export function StakeDataProvider({ children }: { children: ReactNode }) {
  const { userId, marketRefPriceInUsd } = useStaticPoolDataContext();
  const { preferredConnectionMode } = useConnectionStatusContext();
  const { chainId } = useProtocolDataContext();
  const { reserves } = useDynamicPoolDataContext();

  const isRPCMandatory = true;
  const isRPCActive = preferredConnectionMode === ConnectionMode.rpc || isRPCMandatory;

  const stakingService = new StakingService(getProvider(chainId), {
    TOKEN_STAKING_ADDRESS: '',
    STAKING_HELPER_ADDRESS: '',
  });

  const {
    loading: rpcDataLoading,
    data: rpcData,
    refresh,
  } = useStakeDataWithRpc(stakeConfig[chainId as CustomChainId], chainId, userId, !isRPCActive);

  if (isRPCActive && rpcDataLoading) {
    return <Preloader withText={true} />;
  }

  if (!rpcData) {
    return <ErrorPage />;
  }

  const computedData = computeStakeData(rpcData, marketRefPriceInUsd, reserves);

  return (
    <StakeDataContext.Provider
      value={{
        chainId: chainId,
        stakeConfig: stakeConfig[chainId as CustomChainId],
        stakeData: computedData,
        stakingService: stakingService,
        refresh: isRPCActive ? refresh : async () => {},
      }}
    >
      {children}
    </StakeDataContext.Provider>
  );
}

export const useStakeDataContext = () => useContext(StakeDataContext);

import { defineMessages } from 'react-intl';

export default defineMessages({
  apr: { defaultMessage: 'APR' },

  tooltipText: {
    defaultMessage:
      'Participating in this Aave market gives rewards in {token} token. The APR is just a representation on how these rewards would be during a 1-year period',
  },
});

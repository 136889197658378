import {
  assetsList as assetsListFromKit,
  Asset,
  assetsOrder as assetsOrderFromKit,
  STABLE_ASSETS as stableAssetsFromKit,
} from '@aave/aave-ui-kit';

export const assetsList: Asset[] = assetsListFromKit;
export const assetsOrder: string[] = [
  'PHUX',
  'PLS',
  'PLSX',
  'HEX',
  'PHEX',
  'INC',
  'HDRN',
  'SHIB',
  'WDAI',
  'WUSDC',
  'WUSDT',
  ...assetsOrderFromKit,
];
export const stableAssets: string[] = [...stableAssetsFromKit];
export const excludedAssets: string[] = ['CST', 'USDL']; // use all upper cases

// add custom assets
// assetsList.push({
//   name: 'PHIAT',
//   symbol: 'PHIAT',
//   color: '#b6509e',
//   icon: 'https://phatty.io/img/tokens/phiat.png',
// });
assetsList.push({
  name: 'PHUX',
  symbol: 'PHUX',
  color: '#d1468e',
  icon: 'https://phatty.io/img/tokens/phux.png',
});
assetsList.push({
  name: 'PLS',
  symbol: 'PLS',
  color: '#8007fb',
  icon: 'https://phatty.io/img/tokens/wpls.webp',
});
assetsList.push({
  name: 'HEX',
  symbol: 'HEX',
  color: '#b6509e',
  icon: 'https://phatty.io/img/tokens/hex.svg',
});
assetsList.push({
  name: 'Hedron',
  symbol: 'HDRN',
  color: '#b6509e',
  icon: 'https://phatty.io/img/tokens/hdrn.png',
});
// assetsList.push({
//   name: 'HELGO',
//   symbol: 'HELGO',
//   color: '#b6509e',
//   icon: 'https://phiat.io/public/images/helgo2.png',
// });
assetsList.push({
  name: 'PulseX',
  symbol: 'PLSX',
  color: '#fe000a',
  icon: 'https://phatty.io/img/tokens/pulsex.jpeg',
});
assetsList.push({
  name: 'Incentive',
  symbol: 'INC',
  color: '#00ff87',
  icon: 'https://phatty.io/img/tokens/inc.png',
});
assetsList.push({
  name: 'Shiba Inu',
  symbol: 'SHIB',
  color: '#b6509e',
  icon: 'https://phatty.io/img/tokens/shiba.webp',
});

// find stable coins and readd as bridged tokens for PulseChain
let asset = assetsList.find((asset) => asset.symbol === 'USDC');
if (asset) {
  assetsList.push({
    name: 'USDC from Ethereum',
    symbol: 'WUSDC',
    color: asset.color,
    icon: asset.icon,
  });
  stableAssets.push('WUSDC');
}
asset = assetsList.find((asset) => asset.symbol === 'USDT');
if (asset) {
  assetsList.push({
    name: 'USDT from Ethereum',
    symbol: 'WUSDT',
    color: asset.color,
    icon: asset.icon,
  });
  stableAssets.push('WUSDT');
}
asset = assetsList.find((asset) => asset.symbol === 'DAI');
if (asset) {
  assetsList.push({
    name: 'DAI from Ethereum',
    symbol: 'WDAI',
    color: asset.color,
    icon: asset.icon,
  });
  stableAssets.push('WDAI');
}

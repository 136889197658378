import { MarketDataType } from '../../helpers/config/types';
import { CustomChainId } from '../networks';

import * as logos from './images';

export enum CustomMarket {
  proto_plsmainnet = 'proto_plsmainnet',
  proto_mainnet = 'proto_mainnet',
}

export const marketsData: { [key in keyof typeof CustomMarket]: MarketDataType } = {
  [CustomMarket.proto_plsmainnet]: {
    chainId: CustomChainId.plsmainnet,
    name: 'PulseChain',
    activeLogo: logos.pls,
    logo: logos.pls,
    aTokenPrefix: 'ph',
    enabledFeatures: {
      staking: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x746fE703B81EA56499C62A447d67183C850DcAcA', // LendingPoolAddressesProvider
      LENDING_POOL: '0xC14B5DE7fbdFF428f64AA9E7E240EA342EE9a3A3', // LendingPool
      WETH_GATEWAY: '0xABaB0c99eC3FE4b5858Eb54eD18dEDCA223a77B4', // WETHGateway
    },
  },
  [CustomMarket.proto_mainnet]: {
    chainId: CustomChainId.mainnet,
    name: 'Ethereum',
    activeLogo: logos.eth,
    logo: logos.eth,
    aTokenPrefix: 'ph',
    enabledFeatures: {
      staking: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xECF821B8d081AFbbF967DA27117C6221714A2BA6',
      LENDING_POOL: '0xD55B0B380a84B365BbA7a9eeD4dD6156eB0CCB1a',
      WETH_GATEWAY: '0x85a0914fD8a08d85d2D64a10d4179657c7d4cd17',
    },
  },
} as const;

/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IUiStakeDataProvider,
  IUiStakeDataProviderInterface,
} from "../IUiStakeDataProvider";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IPhiatFeeDistribution",
        name: "feeDistributor",
        type: "address",
      },
    ],
    name: "getStakingData",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "stakingToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "totalSupply",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalStakedSupply",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "stakingTokenPrecision",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "rewardDuration",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "unstakeDuration",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "withdrawDuration",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "address",
                name: "token",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            internalType: "struct IPhiatFeeDistribution.RewardAmount[]",
            name: "rewardsPerToken",
            type: "tuple[]",
          },
          {
            internalType: "uint256",
            name: "rewardDurationReturn",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "phuxUsdPrice",
            type: "uint256",
          },
        ],
        internalType: "struct IUiStakeDataProvider.StakingData",
        name: "data",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IPhiatFeeDistribution",
        name: "feeDistributor",
        type: "address",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getStakingUserData",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "stakingToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "totalSupply",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalStakedSupply",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "stakingTokenPrecision",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "rewardDuration",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "unstakeDuration",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "withdrawDuration",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "address",
                name: "token",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            internalType: "struct IPhiatFeeDistribution.RewardAmount[]",
            name: "rewardsPerToken",
            type: "tuple[]",
          },
          {
            internalType: "uint256",
            name: "rewardDurationReturn",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "phuxUsdPrice",
            type: "uint256",
          },
        ],
        internalType: "struct IUiStakeDataProvider.StakingData",
        name: "data",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "walletBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "stakedBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "unstakedBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "withdrawTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "withdrawableBalance",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expirationTimestamp",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "address",
                name: "token",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            internalType: "struct IPhiatFeeDistribution.RewardAmount[]",
            name: "claimableRewards",
            type: "tuple[]",
          },
        ],
        internalType: "struct IUiStakeDataProvider.UserStakingData",
        name: "userData",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class IUiStakeDataProvider__factory {
  static readonly abi = _abi;
  static createInterface(): IUiStakeDataProviderInterface {
    return new utils.Interface(_abi) as IUiStakeDataProviderInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IUiStakeDataProvider {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as IUiStakeDataProvider;
  }
}

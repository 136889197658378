import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { defaultMessage: 'Your transaction overview' },
  description: {
    defaultMessage:
      'These are your transaction details. Make sure to check if this is correct before submitting.',
  },
  cancelUnstake: { defaultMessage: 'Cancel Unstaking' },
  cancelUnstakeAsset: { defaultMessage: 'Cancel unstaking {asset}' },
  boxDescription: { defaultMessage: 'Please submit to cancel unstaking' },
  backToStaking: { defaultMessage: 'Back to staking' },

  blockingError: { defaultMessage: 'Can not cancel unstaking' },
});

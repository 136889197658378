import { defineMessages } from 'react-intl';

export default defineMessages({
  errorPageDescription: { defaultMessage: 'Incorrect transaction' },
  errorStableRateNotEnabled: { defaultMessage: 'The Stable Rate is not enabled for this currency' },
  errorNotEnoughLiquidity: {
    defaultMessage: 'There are not enough funds in the {currencySymbol} reserve to borrow',
  },
  errorNotEnoughCollateral: {
    defaultMessage: 'Your collateral is not enough to borrow this amount',
  },
  errorBorrowingNotAvailable: {
    defaultMessage: 'Borrowing is currently unavailable for {currencySymbol}.',
  },
  caption: { defaultMessage: 'Borrow overview' },
  boxDescription: { defaultMessage: 'Please submit to borrow' },
  approveDescription: { defaultMessage: 'Please approve before borrowing' },
  valueRowTitle: { defaultMessage: 'Amount' },
  APYRowTitle: { defaultMessage: 'Interest (APY)' },
  currentBorrowRateTitle: { defaultMessage: 'Current {borrowRateMode} rate' },
  borrowRateMode: { defaultMessage: 'New {borrowRateMode} rate' },
  rateTypeRowTitle: { defaultMessage: 'Interest rate type' },
  healthFactorRowTitle: { defaultMessage: 'New health factor' },
  originationFeeRowTitle: { defaultMessage: 'Origination fee {percent}%' },
  variable: { defaultMessage: 'Variable' },
  stable: { defaultMessage: 'Stable' },

  connectWallet: { defaultMessage: 'Please connect a wallet' },
  connectWalletDescription: {
    defaultMessage: 'We couldn’t detect a wallet. Connect a wallet to borrow.',
  },
  errorBorrowingAreTooSmall: { defaultMessage: "You can't borrow less then {amount}{symbol}" },
});

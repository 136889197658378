import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: { defaultMessage: 'Staking' },

  staked: { defaultMessage: '{symbol} Staked' },
  unlocking: { defaultMessage: '{symbol} Unlocking' },
  withdrawable: { defaultMessage: '{symbol} Withdrawable' },
  claim: { defaultMessage: 'Claim rewards' },
  howToUnstake: { defaultMessage: 'How to unstake' },
  unstake: { defaultMessage: 'Unstake now' },
  cancel: { defaultMessage: 'Cancel now' },
  withdraw: { defaultMessage: 'Withdraw now' },
  tillWithdraw: { defaultMessage: 'Till withdraw' },
  leftToWithdraw: { defaultMessage: 'Left to withdraw' },
  days: { defaultMessage: 'Days' },
  usd: { defaultMessage: 'USD (Est.)' },

  stakingAPY: { defaultMessage: 'Staking APR' },
  unlockingPeriod: { defaultMessage: 'Unlocking period' },
  withdrawWindow: { defaultMessage: 'Withdraw window' },

  totalRewards: { defaultMessage: 'Total rewards claimed' },

  noWalletConnect: { defaultMessage: 'Please connect a wallet' },
  noWalletConnectDescription: {
    defaultMessage: 'We couldn’t detect a wallet. Connect a wallet to stake.',
  },
});

import React from 'react';
import { useIntl } from 'react-intl';

import { ComputedReserveData } from '../../libs/pool-data-provider';

import messages from './messages';

export default function MarketLimitTooltip({
  symbol,

  maxGlobalDepositSize,
  hasMaxGlobalDepositSizeLimit,

  maxIndividualDepositSize,
  hasMaxIndividualDepositSizeLimit,

  rawMaxGlobalBorrowSize,
  hasMaxGlobalBorrowSizeLimit,

  maxIndividualBorrowSize,
  hasMaxIndividualBorrowSizeLimit,

  maxBorrowBps,

  minIndividualDepositSize,
  hasMinIndividualDepositSizeLimit,
}: ComputedReserveData) {
  const intl = useIntl();

  if (
    hasMaxGlobalDepositSizeLimit &&
    hasMaxIndividualDepositSizeLimit &&
    hasMaxGlobalBorrowSizeLimit &&
    hasMaxIndividualBorrowSizeLimit &&
    hasMinIndividualDepositSizeLimit &&
    maxBorrowBps > 0
  ) {
    return (
      <>
        <p>
          {intl.formatMessage(messages.marketTooltipP1V1, {
            asset: symbol,
            maxGlobalDepositSize: intl.formatNumber(Number(maxGlobalDepositSize), {
              maximumFractionDigits: 0,
            }),
            maxGlobalBorrowSize: intl.formatNumber(Number(rawMaxGlobalBorrowSize), {
              maximumFractionDigits: 0,
            }),
            maxBorrowBps: intl.formatNumber(Number(maxBorrowBps) / 100, {
              maximumFractionDigits: 0,
            }),
          })}
        </p>
        <p>
          {intl.formatMessage(messages.marketTooltipP2, {
            asset: symbol,
            minIndividualDepositSize: intl.formatNumber(Number(minIndividualDepositSize), {
              maximumFractionDigits: 5,
            }),
            maxIndividualDepositSize: intl.formatNumber(Number(maxIndividualDepositSize), {
              maximumFractionDigits: 5,
            }),
            maxIndividualBorrowSize: intl.formatNumber(Number(maxIndividualBorrowSize), {
              maximumFractionDigits: 5,
            }),
          })}
        </p>
        <p>{intl.formatMessage(messages.marketToolTipP3)}</p>
      </>
    );
  } else if (
    hasMaxIndividualDepositSizeLimit &&
    hasMaxIndividualDepositSizeLimit &&
    hasMinIndividualDepositSizeLimit &&
    maxBorrowBps > 0
  ) {
    return (
      <>
        <p>
          {intl.formatMessage(messages.marketTooltipP1V2, {
            asset: symbol,
            maxBorrowBps: intl.formatNumber(Number(maxBorrowBps) / 100, {
              maximumFractionDigits: 0,
            }),
          })}
        </p>
        <p>
          {intl.formatMessage(messages.marketTooltipP2, {
            asset: symbol,
            minIndividualDepositSize: intl.formatNumber(Number(minIndividualDepositSize), {
              maximumFractionDigits: 5,
            }),
            maxIndividualDepositSize: intl.formatNumber(Number(maxIndividualDepositSize), {
              maximumFractionDigits: 5,
            }),
            maxIndividualBorrowSize: intl.formatNumber(Number(maxIndividualBorrowSize), {
              maximumFractionDigits: 5,
            }),
          })}
        </p>
        <p>{intl.formatMessage(messages.marketToolTipP3)}</p>
      </>
    );
  } else {
    return <></>;
  }
}

import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { constants } from 'ethers';
import BigNumber from 'bignumber.js';
import queryString from 'query-string';
import { useThemeContext } from '@aave/aave-ui-kit';
import {
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  transactionType,
} from '@aave/contract-helpers';

import { useStakeDataContext } from '../../../../libs/pool-data-provider/hooks/use-stake-data-context';
import { PhiatFeeDistribution__factory } from '../../../../libs/pool-data-provider/contracts/factories/PhiatFeeDistribution__factory';
import { getProvider } from '../../../../helpers/config/markets-and-network-config';

import TextFAQLink from '../../components/TextFAQLink';
import RiskInfoPanel from '../../components/RiskInfoPanel';
import BasicForm from '../../../../components/forms/BasicForm';
import defaultMessages from '../../../../defaultMessages';
import messages from './messages';
import staticStyles from './style';
import TextPurchaseLink from '../../components/TextPurchaseLink';

export default function StakingMain() {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { chainId, stakeConfig, stakeData, stakingService } = useStakeDataContext();
  const history = useHistory();

  const handleSubmit = (amount: string) => {
    const query = queryString.stringify({ amount });
    history.push(`/staking/confirmation?${query}`);
  };

  const convertedAmount = new BigNumber(stakeData.userWalletBalance)
    .multipliedBy(new BigNumber(10).exponentiatedBy(stakeData.stakingTokenPrecision))
    .toFixed(0);
  const handleGetTransactions =
    (userId: string) => async (): Promise<EthereumTransactionTypeExtended[]> => {
      if (convertedAmount === '0') return [];

      const txs: EthereumTransactionTypeExtended[] = [];
      // check for approval
      const { isApproved, approve } = stakingService.erc20Service;
      const approved: boolean = await isApproved({
        token: stakeConfig.stakingToken,
        user: userId,
        spender: stakeConfig.feeDistribution,
        amount: convertedAmount,
      });
      if (!approved) {
        const approveTx = approve({
          user: userId,
          token: stakeConfig.stakingToken,
          spender: stakeConfig.feeDistribution,
          amount: constants.MaxUint256.toString(),
        });
        txs.push(approveTx);
      }
      // stake
      const stakingContract = PhiatFeeDistribution__factory.connect(
        stakeConfig.feeDistribution,
        getProvider(chainId)
      );
      const txCallback: () => Promise<transactionType> = stakingService.generateTxCallback({
        rawTxMethod: async () => stakingContract.populateTransaction.stake(convertedAmount),
        from: userId,
        gasSurplus: 20,
      });
      txs.push({
        tx: txCallback,
        txType: eEthereumTxType.STAKE_ACTION,
        gas: stakingService.generateTxPriceEstimation(txs, txCallback),
      });

      return txs;
    };

  return (
    <div className="StakingMain">
      <BasicForm
        title={intl.formatMessage(messages.caption)}
        description={intl.formatMessage(messages.description, {
          asset: <strong>{stakeConfig.stakingTokenSymbol}</strong>,
        })}
        amountFieldTitle={intl.formatMessage(messages.availableToStake)}
        maxAmount={stakeData.userWalletBalance}
        currencySymbol={stakeConfig.stakingTokenSymbol}
        onSubmit={handleSubmit}
        submitButtonTitle={intl.formatMessage(defaultMessages.stake)}
        getTransactionData={handleGetTransactions}
      />
      <RiskInfoPanel />

      <TextPurchaseLink />
      <TextFAQLink />

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        @import 'src/_mixins/screen-size';

        .StakingMain {
          &__description {
            color: ${currentTheme.textDarkBlue.hex};
          }
        }
      `}</style>
    </div>
  );
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  caption: { defaultMessage: 'Unlocking Period & Withdraw Window' },
  descriptionFirst: {
    defaultMessage:
      'Unstaking Your {asset} token will incur a two-week unlocking period. You can only withdraw your {asset} after the unlocking period ends and the withdraw window is then activated.',
  },
  descriptionSecond: {
    defaultMessage:
      'Should you forget and not withdraw your {asset} during the withdraw window, unstaking will end automatically. No more unstaking is allowed until the active unstaking is canceled or the unlocked {asset} are withdrawn.',
  },
  descriptionThird: {
    defaultMessage:
      'Locked and withdrawable {asset} are still staked, counted in the staked balance, and earning fees.',
  },
});

import { useIntl } from 'react-intl';
import queryString from 'query-string';
import { valueToBigNumber } from '@aave/protocol-js';

import { useTxBuilderContext } from '../../../../libs/tx-provider';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import NoDataPanel from '../../../../components/NoDataPanel';
import BasicForm from '../../../../components/forms/BasicForm';
import DepositCurrencyWrapper from '../../components/DepositCurrencyWrapper';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from '../../../../components/RouteParamsValidationWrapper';

import { getAssetInfo } from '../../../../helpers/config/assets-config';
import { getMaxAmountToDeposit } from '../../../../helpers/market-limits';
import MarketLimitTooltip from '../../../../components/MarketLimitTooltip';

import messages from './messages';
import staticStyles from './style';
import { CompactNumber } from '../../../../components/basic/CompactNumber';

interface DepositAmountProps
  extends Pick<
    ValidationWrapperComponentProps,
    'currencySymbol' | 'poolReserve' | 'history' | 'walletBalance' | 'user' | 'userReserve'
  > {}

function DepositAmount({
  currencySymbol,
  poolReserve,
  user,
  userReserve,
  history,
  walletBalance,
}: DepositAmountProps) {
  const intl = useIntl();
  const { networkConfig } = useProtocolDataContext();
  const { lendingPool } = useTxBuilderContext();

  const asset = getAssetInfo(currencySymbol);

  const walletBalance_ = valueToBigNumber(walletBalance);
  let rawMaxAmountToDeposit = walletBalance_;
  if (rawMaxAmountToDeposit.gt(0) && poolReserve.symbol.toUpperCase() === networkConfig.baseAsset) {
    // keep it for tx gas cost
    rawMaxAmountToDeposit = rawMaxAmountToDeposit.minus(networkConfig.gasReserve);
  }
  const maxAmountToDeposit = getMaxAmountToDeposit(rawMaxAmountToDeposit, poolReserve, userReserve);
  const minAmountToDeposit = userReserve?.minIndividualDepositSize;
  const hasMinAmount = minAmountToDeposit && minAmountToDeposit !== '0';
  const isMaxEnoughForMin = hasMinAmount ? maxAmountToDeposit.gte(minAmountToDeposit) : true;

  const handleSubmit = (amount: string) => {
    const query = queryString.stringify({ amount });
    history.push(`${history.location.pathname}/confirmation?${query}`);
  };

  const handleTransactionData = (userId: string) => async () => {
    return await lendingPool.deposit({
      user: userId,
      reserve: poolReserve.underlyingAsset,
      amount: maxAmountToDeposit.toString(10),
      referralCode: undefined,
    });
  };

  return (
    <DepositCurrencyWrapper
      currencySymbol={currencySymbol}
      poolReserve={poolReserve}
      walletBalance={walletBalance}
      userReserve={userReserve}
      user={user}
    >
      {!maxAmountToDeposit.eq('0') && isMaxEnoughForMin && (
        <BasicForm
          title={intl.formatMessage(messages.title)}
          description={intl.formatMessage(
            hasMinAmount ? messages.descriptionWithMin : messages.description
          )}
          amountFieldTitle={intl.formatMessage(messages.amountTitle)}
          maxAmount={maxAmountToDeposit.toString(10)}
          isMaxCompact={!!hasMinAmount || maxAmountToDeposit.gt(1e6)}
          minAmount={hasMinAmount ? minAmountToDeposit : undefined}
          isMinCompact={true}
          currencySymbol={currencySymbol}
          onSubmit={handleSubmit}
          maxDecimals={poolReserve.decimals}
          getTransactionData={handleTransactionData}
        />
      )}
      {!maxAmountToDeposit.eq('0') && hasMinAmount && !isMaxEnoughForMin && (
        <NoDataPanel
          title={intl.formatMessage(messages.notEnoughTitle)}
          description={[
            <div key="DepositAmount__notEnoughDescription">
              {intl.formatMessage(messages.notEnoughDescription, {
                currencySymbol: asset.formattedName,
                minimum: (
                  <b>
                    <CompactNumber
                      value={minAmountToDeposit}
                      maximumFractionDigits={4}
                      minimumFractionDigits={0}
                    />
                  </b>
                ),
              })}
            </div>,
          ]}
        ></NoDataPanel>
      )}
      {maxAmountToDeposit.eq('0') && (
        <NoDataPanel
          title={
            !user
              ? intl.formatMessage(messages.connectWallet)
              : walletBalance_.eq('0')
              ? intl.formatMessage(messages.noDataTitle)
              : intl.formatMessage(messages.exceedLimitTitle)
          }
          description={
            !user
              ? intl.formatMessage(messages.connectWalletDescription)
              : walletBalance_.eq('0')
              ? intl.formatMessage(messages.noDataDescription, {
                  currencySymbol: asset.formattedName,
                })
              : [
                  <div
                    className="DepositAmount__marketLimitTooltip"
                    key="DepositAmount__marketLimitTooltip"
                  >
                    <MarketLimitTooltip {...poolReserve} />
                  </div>,
                ]
          }
          linkTo={undefined}
          buttonTitle={undefined}
          withConnectButton={!user}
        />
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </DepositCurrencyWrapper>
  );
}

export default routeParamValidationHOC({
  withWalletBalance: true,
})(DepositAmount);

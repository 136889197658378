import { useState, useEffect } from 'react';

import { getProvider } from '../../../helpers/config/markets-and-network-config';
import { StakeData } from '../types/stake';
import { CustomChainId } from '../../../ui-config/networks';
import { IUiStakeDataProvider__factory } from '../contracts/factories/IUiStakeDataProvider__factory';
import { StakeConfig } from '../../../ui-config';

export function useStakeDataWithRpc(
  stakeConfig: StakeConfig,
  chainId: CustomChainId,
  user?: string,
  skip: boolean = false,
  poolingInterval: number = 30
) {
  const [loading, setLoading] = useState(true);
  const [stakeData, setStakeData] = useState<StakeData>();

  const loadStakeData = async (userAddress: string | undefined, stakeConfig: StakeConfig) => {
    const dataProviderContract = IUiStakeDataProvider__factory.connect(
      stakeConfig.dataProvider,
      getProvider(chainId)
    );

    try {
      if (userAddress === undefined || userAddress === '') {
        const data = await dataProviderContract.getStakingData(stakeConfig.feeDistribution);
        setStakeData({
          stakeConfig: stakeConfig,
          chainId: chainId,
          data: data,
          userData: undefined,
        });
      } else {
        const data = await dataProviderContract.getStakingUserData(
          stakeConfig.feeDistribution,
          userAddress
        );
        setStakeData({
          stakeConfig: stakeConfig,
          chainId: chainId,
          data: data.data,
          userData: data.userData,
        });
      }
    } catch (e) {
      console.log('Stake data loading error', e);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);

    if (!skip) {
      loadStakeData(user, stakeConfig);
      const intervalId = setInterval(
        () => loadStakeData(user, stakeConfig),
        poolingInterval * 1000
      );
      return () => clearInterval(intervalId);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, skip, poolingInterval, stakeConfig, chainId]);

  return {
    loading,
    data: stakeData,
    refresh: async () => await loadStakeData(user, stakeConfig),
  };
}

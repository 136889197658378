import { defineMessages } from 'react-intl';

export default defineMessages({
  error: { defaultMessage: 'Your wallet balance of {userReserveSymbol} is not enough' },
  warningMessage: {
    defaultMessage:
      'Mind that due to the continuous accumulation of interest, a small amount could be remaining, as your wallet balance is just above the current amount pending to repay',
  },

  caption: { defaultMessage: 'Repay overview' },
  boxDescription: { defaultMessage: 'Please submit to repay' },
  approveDescription: { defaultMessage: 'Please approve before repaying' },

  rowTitle: { defaultMessage: 'Amount to repay' },
  secondRowTitle: { defaultMessage: 'Remaining to repay' },
  secondRowTitleSubTitle: {
    defaultMessage: 'You don’t have enough funds to repay the full amount',
  },
  currentHealthFactor: { defaultMessage: 'Current Health Factor' },
  nextHealthFactor: { defaultMessage: 'Next Health Factor' },
  thirdRowTitle: { defaultMessage: 'Health factor after repay' },

  connectWallet: { defaultMessage: 'Please connect a wallet' },
  connectWalletDescription: {
    defaultMessage: 'We couldn’t detect a wallet. Connect a wallet to repay.',
  },
});

import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .StakingWrapper {
    margin-top: 50px !important;
    margin-bottom: 10px !important;
    @include respond-to(sm) {
      margin-top: 0 !important;
      display: block !important;
    }

    &__mobile-switcher {
      display: none;
      @include respond-to(sm) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
      }
    }

    &__content-inner {
      display: flex;
      flex: 1;
      @include respond-to(sm) {
        display: block;
      }
    }

    &__content-left,
    &__content-right {
      border-radius: $borderRadius;
      box-shadow: $boxShadow;
    }

    &__content-left {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px 10px;
      position: relative;
      @include respond-to(sm) {
        padding: 20px 0;
        box-shadow: none;
        border-radius: 0;
        background: transparent !important;
      }
    }
    &__contentLeftActive {
      @include respond-to(sm) {
        display: flex;
      }
    }

    &__content-right {
      display: flex;
      flex-direction: column;
      width: 400px;
      margin-left: 20px;
      padding: 30px 15px 20px;
      @include respond-to(xl) {
        width: 340px;
      }
      @include respond-to(lg) {
        width: 310px;
        margin-left: 10px;
        padding: 20px 10px;
      }
      @include respond-to(sm) {
        width: 100%;
        margin: 0;
        padding: 30px 15px 20px;
      }
    }
    &__contentRightActive {
      @include respond-to(sm) {
        display: block;
      }
    }

    &__cards-inner {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      @include respond-to(xl) {
        margin-bottom: 30px;
      }
      @include respond-to(lg) {
        margin-bottom: 20px;
      }
      @include respond-to(sm) {
        margin-bottom: 30px;
        justify-content: center;
      }
    }

    .StakingWrapper__link {
      width: 100%;
    }
    .StakingWrapper__button {
      width: 100%;
      font-size: $medium;
      @include respond-to(xl) {
        font-size: $extraSmall;
      }
      @include respond-to(sm) {
        font-size: $small;
      }
    }

    &__info-seaCreatureInner {
      width: 100%;
      border-radius: $borderRadius;
      height: 40px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      cursor: default;
      @include respond-to(xl) {
        height: 32px;
      }
    }
    &__info-seaCreatureIcon {
      font-size: $large;
      font-weight: bold;
      margin-right: 5px;
      @include respond-to(xl) {
        font-size: $small;
      }
      @include respond-to(sm) {
        font-size: $medium;
      }
    }
    &__info-seaCreatureText {
      font-size: $large;
      font-weight: bold;
      @include respond-to(xl) {
        font-size: $small;
      }
      @include respond-to(sm) {
        font-size: $medium;
      }
    }

    &__info-timerWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__info-timerInner {
      width: 100%;
      border-radius: $borderRadius;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: default;
      @include respond-to(xl) {
        height: 32px;
      }
      .StakingWrapper__timer {
        margin-right: 0 !important;
        opacity: 0.5 !important;
      }
    }
    &__info-timerText {
      margin-top: 1px;
      margin-bottom: 4px;
      font-size: $medium;
      @include respond-to(xl) {
        font-size: $extraSmall;
      }
      @include respond-to(sm) {
        font-size: $small;
      }
    }

    &__unstakeTime-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: $medium;
      margin-top: 10px;
      @include respond-to(xl) {
        font-size: $extraSmall;
      }
      p {
        margin-bottom: 1px;
      }
      .StakingWrapper__unstakeTimer {
        margin-right: 0 !important;
        font-size: $regular !important;
        @include respond-to(xl) {
          font-size: $small !important;
        }
      }
    }

    &__claimDiv {
      position: relative;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__claimValue {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }

    &__claimButton {
      &:hover {
        &:before,
        &:after {
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
      &:active {
        transform: scale(0.98);
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        border-radius: $borderRadius;
        transition: all 0.3s ease;
        background-size: 200% auto !important;
        animation: shine 4.5s linear infinite;
        @keyframes shine {
          to {
            background-position: 200% center;
          }
        }
      }

      &:before,
      &:after {
        top: -2px;
        bottom: -2px;
        left: -2px;
        right: -2px;
      }

      &:before {
        filter: blur(2px);
      }

      &__inner {
        width: 100px;
        min-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        border-radius: $borderRadius;
        position: relative;
        z-index: 2;
        @include respond-to(sm) {
          width: 110px;
          min-height: 54px;
          padding: 0 10px;
        }
        span {
          font-size: $extraSmall;
          @include respond-to(sm) {
            font-size: $regular;
          }
        }
      }
    }

    &__cooldownPeriodTime {
      font-size: $large;
      @include respond-to(xl) {
        font-size: $regular;
      }
      @include respond-to(lg) {
        font-size: $medium;
      }
      @include respond-to(md) {
        font-size: $regular;
      }
    }

    &__row {
      margin-bottom: 15px;
      @include respond-to(xl) {
        margin-bottom: 9px;
      }
      @include respond-to(lg) {
        margin-bottom: 8px;
      }
      @include respond-to(md) {
        margin-bottom: 9px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__rewardRow {
      margin-bottom: 10px;
      @include respond-to(xl) {
        margin-bottom: 6px;
      }
      @include respond-to(lg) {
        margin-bottom: 5px;
      }
      @include respond-to(md) {
        margin-bottom: 6px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

export default staticStyles;

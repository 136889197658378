import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: { defaultMessage: '{currencySymbol} Reserve overview' },

  userCaption: { defaultMessage: 'Your information' },
  noConnectWalletCaption: { defaultMessage: 'Please connect a wallet' },
  noConnectWalletDescription: {
    defaultMessage:
      'We couldn’t detect a wallet. Please connect a wallet to view your personal information here.',
  },

  provideLiquidity: { defaultMessage: 'Provide liquidity {here}' },
  here: { defaultMessage: 'here' },
});

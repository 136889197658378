import { defineMessages } from 'react-intl';

export default defineMessages({
  asset: { defaultMessage: 'Asset' },
  yourWalletBalance: { defaultMessage: 'Your wallet balance' },
  availableToDeposit: { defaultMessage: 'Available to deposit' },
  yourBalanceinPhiat: { defaultMessage: 'Your balance' },
  APY: { defaultMessage: 'APY' },

  dividerTitle: {
    defaultMessage: 'To make a deposit, transfer any of these assets to your wallet.',
  },
  connectWallet: { defaultMessage: 'To make a deposit, please connect a wallet' },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage:
      'Unclaimed phTokens are already compounding inside of the Phiat protocol. Therefore, there is no advantage to claiming unless you intend to withdraw the specific tokens.  Claiming repeatedly just incurs gas fees and should be avoided.',
  },
  dashboard: {
    defaultMessage: 'dashboard',
  },
  success: {
    defaultMessage: 'Claimed rewards will be reflected on {dashboard}.',
  },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  amountTitle: { defaultMessage: 'Available to withdraw' },
  formDescription: { defaultMessage: 'How much do you want to withdraw?' },
  formDescriptionWithMin: {
    defaultMessage:
      'How much do you want to withdraw? You must either withdraw the full balance or leave at least {minimum} {currencySymbol} to meet the minimum deposit requirement. Failure to do this will lead to a failed transaction. ',
  },

  pageTitle: { defaultMessage: `Withdraw {currencySymbol}` },

  connectWallet: { defaultMessage: 'Please connect a wallet' },
  connectWalletDescription: {
    defaultMessage: 'We couldn’t detect a wallet. Connect a wallet to withdraw.',
  },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  errorYouDoNotHaveEnoughFundsToWithdrawThisAmount: {
    defaultMessage: "You don't have enough funds to withdraw this amount",
  },
  errorPoolDoNotHaveEnoughFundsToWithdrawThisAmount: {
    defaultMessage:
      'These funds have been borrowed and are not available for withdrawal at this time.',
  },
  errorCanNotWithdrawThisAmount: {
    defaultMessage: "You can't withdraw this amount because it will cause collateral call",
  },
  caption: { defaultMessage: 'Withdraw overview' },
  boxDescription: { defaultMessage: 'Please submit to withdraw' },
  approveDescription: { defaultMessage: 'Please approve before withdrawal' },
  rowTitle: { defaultMessage: 'Amount' },
  currentHealthFactor: { defaultMessage: 'Current Health Factor' },
  nextHealthFactor: { defaultMessage: 'Next Health Factor' },
  connectWallet: { defaultMessage: 'Please connect a wallet' },
  connectWalletDescription: {
    defaultMessage: 'We couldn’t detect a wallet. Connect a wallet to withdraw.',
  },
  healthFactorDangerousText: {
    defaultMessage:
      'This action will reduce your Health Factor and could lead to {liquidation} of your collateral. Please be cautious and understand the risks.',
  },
  liquidation: { defaultMessage: 'liquidation' },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  yourInformation: { defaultMessage: 'Your information' },

  deposits: { defaultMessage: 'Deposits' },
  yourWalletBalance: { defaultMessage: 'Your wallet balance' },
  youAlreadyDeposited: { defaultMessage: 'You already deposited' },
  collateral: { defaultMessage: 'Use as collateral' },
  depositOffLabel: { defaultMessage: 'No' },
  depositOnLabel: { defaultMessage: 'Yes' },

  borrows: { defaultMessage: 'Borrows' },
  borrowed: { defaultMessage: 'Borrowed' },
  loanToValue: { defaultMessage: 'Loan to value' },
  availableToYou: { defaultMessage: 'Available to you' },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  migrationText: {
    defaultMessage:
      'Deposit and borrow of {token} is disabled due to the ongoing migration. You can still repay and withdraw to migrate your token {here}',
  },
  here: { defaultMessage: 'here' },
  freezedText: { defaultMessage: 'Deposit and borrow of {token} is disabled' },
});

import React from 'react';
import { defaultTheme } from '../../../ui-config/theme';

interface GradientLineProps {
  height?: number;
}

export default function GradientLine({ height }: GradientLineProps) {
  return (
    <span className="GradientLine">
      <style jsx={true}>{`
        .GradientLine {
          width: 100%;
          height: ${height || 1}px;
          display: block;
          background: ${defaultTheme.gradientBackground};
        }
      `}</style>
    </span>
  );
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  caption: { defaultMessage: '{symbol} Reserve Overview' },

  utilizationRate: { defaultMessage: 'Utilization rate' },
  availableLiquidity: { defaultMessage: 'Available liquidity' },
  depositAPY: { defaultMessage: 'Deposit APY' },
  depositAPR: { defaultMessage: 'Deposit APR' },
  canBeUsedAsCollateral: { defaultMessage: 'Can be used as collateral' },
  no: { defaultMessage: 'No' },
  yes: { defaultMessage: 'Yes' },
  assetPrice: { defaultMessage: 'Asset price' },
  maximumLTV: { defaultMessage: 'Maximum LTV' },
  liquidationThreshold: { defaultMessage: 'Liquidation threshold' },
  liquidationPenalty: { defaultMessage: 'Liquidation penalty' },

  stableAPY: { defaultMessage: 'Stable borrow APY' },
  variableAPY: { defaultMessage: 'Variable borrow APY' },
});

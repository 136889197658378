import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { defaultMessage: 'Rewards overview' },
  description: {
    defaultMessage:
      'These are your rewards for staking {asset}. Make sure to check if this is correct before submitting.',
  },
  claim: { defaultMessage: 'Claim' },
  boxDescription: { defaultMessage: 'Please submit to claim' },
  backToStaking: { defaultMessage: 'Back to staking' },

  rewardsToBeClaimed: { defaultMessage: 'Rewards to be claimed' },
  total: { defaultMessage: 'Estimated Total Value' },
  usd: { defaultMessage: 'USD' },

  notHaveEnoughRewards: { defaultMessage: 'You do not have enough rewards to claim' },
});

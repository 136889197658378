import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { defaultMessage: 'Your transaction overview' },
  description: {
    defaultMessage:
      'These are your transaction details. Make sure to check if this is correct before submitting.',
  },
  unstake: { defaultMessage: 'Unstake' },
  unstakeAsset: { defaultMessage: 'Unstake {asset}' },
  boxDescription: { defaultMessage: 'Please submit to unstake' },
  backToStaking: { defaultMessage: 'Back to staking' },

  blockingError: { defaultMessage: 'Staked amount are less' },
});

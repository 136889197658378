import { defineMessages } from 'react-intl';

export default defineMessages({
  assets: { defaultMessage: 'Assets' },
  marketSize: { defaultMessage: 'Market size' },
  availableForDeposit: { defaultMessage: 'Available for deposit' },
  totalBorrowed: { defaultMessage: 'Total borrowed' },
  availableToBorrow: { defaultMessage: 'Available to borrow' },
  depositAPY: { defaultMessage: 'Deposit APY' },
  borrowAPY: { defaultMessage: 'Borrow APY' },
  stable: { defaultMessage: 'Stable' },
  variable: { defaultMessage: 'Variable' },
});

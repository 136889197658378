import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { defaultMessage: 'Staking overview' },
  description: {
    defaultMessage:
      'These are your transaction details for staking {asset}. Make sure to check if this is correct before submitting.',
  },
  safetyModule: { defaultMessage: 'Safety Module' },
  stake: { defaultMessage: 'Stake {asset}' },
  boxDescription: { defaultMessage: 'Please submit to stake' },
  backToStaking: { defaultMessage: 'Back to staking' },

  notEnoughBalance: { defaultMessage: 'Not enough {asset} to stake' },
  buttonTitle: { defaultMessage: 'Stake' },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  marketSize: { defaultMessage: 'Market size' },
  availableForDeposit: { defaultMessage: 'Available for deposit' },
  totalBorrowed: { defaultMessage: 'Total borrowed' },
  availableToBorrow: { defaultMessage: 'Available to borrow' },

  deposit: { defaultMessage: 'Deposit' },
  borrow: { defaultMessage: 'Borrow' },
  variable: { defaultMessage: 'Variable' },
  stable: { defaultMessage: 'Stable' },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  caption: { defaultMessage: 'Choose your language' },
  english: { defaultMessage: 'English' },
  spanish: { defaultMessage: 'Spanish' },
  french: { defaultMessage: 'French' },
  russian: { defaultMessage: 'Russian' },
  chinese: { defaultMessage: 'Chinese' },
  italian: { defaultMessage: 'Italian' },
  japanese: { defaultMessage: 'Japanese' },
  turkish: { defaultMessage: 'Turkish' },
  vietnamese: { defaultMessage: 'Vietnamese' },
  korean: { defaultMessage: 'Korean' },
  portuguese: { defaultMessage: 'Portuguese' },
});

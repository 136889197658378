import { defineMessages } from 'react-intl';

export default defineMessages({
  caption: { defaultMessage: 'Reserve Status & Configuration' },
  totalBorrowed: { defaultMessage: 'Total Borrowed' },
  availableLiquidity: { defaultMessage: 'Available Liquidity' },
  reserveSize: { defaultMessage: 'Reserve size' },
  utilisationRate: { defaultMessage: 'Utilisation rate' },

  depositAPY: { defaultMessage: 'Deposit APY' },
  depositAPR: { defaultMessage: 'Deposit APR' },
  variableBorrowing: { defaultMessage: 'Variable borrowing' },
  borrowAPY: { defaultMessage: 'Borrow APY' },
  borrowAPR: { defaultMessage: 'Borrow APR' },
  overTotal: { defaultMessage: '% over total' },

  maximumLTV: { defaultMessage: 'Maximum LTV' },
  liquidationThreshold: { defaultMessage: 'Liquidation threshold' },
  liquidationPenalty: { defaultMessage: 'Liquidation penalty' },
  usedAsCollateral: { defaultMessage: 'Used as collateral' },
  stableBorrowing: { defaultMessage: 'Stable borrowing' },

  provideLiquidity: { defaultMessage: 'Provide liquidity {here}' },
  here: { defaultMessage: 'here' },
});

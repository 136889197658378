import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { useThemeContext } from '@aave/aave-ui-kit';

import StakeHelpModal from '../../../../components/HelpModal/StakeHelpModal';
import Value from '../../../../components/basic/Value';

import staticStyles from './style';
import { defaultTheme } from '../../../../ui-config/theme';

interface SidePanelCardProps {
  title: string | ReactNode;
  value: string | number;
  withGradientBorder?: boolean;
  children: ReactNode;
}

export default function SidePanelCard({
  title,
  value,
  withGradientBorder,
  children,
}: SidePanelCardProps) {
  const { currentTheme, xl, isCurrentThemeDark } = useThemeContext();

  const iconSize = xl ? 14 : 18;

  return (
    <div
      className={classNames('SidePanelCard', {
        SidePanelCard__withGradientBorder: withGradientBorder,
      })}
    >
      <StakeHelpModal className="SidePanelCard__help-icon" text="" iconSize={iconSize} />

      <div className="SidePanelCard__inner">
        <div className="SidePanelCard__value-wrapper">
          <p className="SidePanelCard__title">{title}</p>
          <div className="SidePanelCard__value-inner">
            <Value value={value} />
          </div>
        </div>
        <div className="SidePanelCard__content">{children}</div>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .SidePanelCard {
          color: ${currentTheme.textDarkBlue.hex};
          &:after {
            background: ${currentTheme.textDarkBlue.hex};
          }

          &__withGradientBorder {
            &:before,
            &:after {
              background: ${defaultTheme.rotatingGradientBackground};
            }
          }

          &__inner {
            background: ${isCurrentThemeDark ? '#42475a' : currentTheme.white.hex};
          }
        }
      `}</style>
    </div>
  );
}

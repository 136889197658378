import { useIntl } from 'react-intl';
import {
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  transactionType,
} from '@aave/contract-helpers';
import { TokenIcon } from '@aave/aave-ui-kit';

import { getProvider, marketsData } from '../../../../helpers/config/markets-and-network-config';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { useStakeDataContext } from '../../../../libs/pool-data-provider/hooks/use-stake-data-context';
import { PhiatFeeDistribution__factory } from '../../../../libs/pool-data-provider/contracts/factories/PhiatFeeDistribution__factory';

import Row from '../../../../components/basic/Row';
import StakeTxConfirmationView from '../../components/StakeTxConfirmationView';
import Value from '../../../../components/basic/Value';

import messages from './messages';
import staticStyles from './style';
import ClaimInfoPanel from '../../components/ClaimInfoPanel';

export default function ClaimConfirmation() {
  const intl = useIntl();
  const { currentMarket } = useProtocolDataContext();
  const { userId } = useStaticPoolDataContext();
  const { chainId, stakeConfig, stakeData, stakingService } = useStakeDataContext();

  if (!userId) {
    return null;
  }

  let blockingError = '';
  if (!stakeData.userCanClaim) {
    blockingError = intl.formatMessage(messages.notHaveEnoughRewards);
  }

  const handleGetTransactions = async (): Promise<EthereumTransactionTypeExtended[]> => {
    const stakingContract = PhiatFeeDistribution__factory.connect(
      stakeConfig.feeDistribution,
      getProvider(chainId)
    );
    const txCallback: () => Promise<transactionType> = stakingService.generateTxCallback({
      rawTxMethod: async () => stakingContract.populateTransaction.getReward(),
      from: userId,
      gasSurplus: 20,
    });
    return [
      {
        tx: txCallback,
        txType: eEthereumTxType.STAKE_ACTION,
        gas: stakingService.generateTxPriceEstimation([], txCallback),
      },
    ];
  };

  return (
    <>
      <StakeTxConfirmationView
        caption={intl.formatMessage(messages.title)}
        description={intl.formatMessage(messages.description, {
          asset: <strong>{stakeConfig.stakingTokenSymbol}</strong>,
        })}
        getTransactionsData={handleGetTransactions}
        boxTitle={intl.formatMessage(messages.claim)}
        boxDescription={intl.formatMessage(messages.boxDescription)}
        mainTxName={intl.formatMessage(messages.claim)}
        blockingError={blockingError}
        goToAfterSuccess="/staking"
        successButtonTitle={intl.formatMessage(messages.backToStaking)}
      >
        <Row
          title={intl.formatMessage(messages.rewardsToBeClaimed)}
          className="StakingClaim__titleRow"
        ></Row>
        {stakeData.userRewards.map(({ tokenSymbol, amount }, index) => (
          <Row
            title={
              <TokenIcon
                tokenSymbol={tokenSymbol}
                height={20}
                width={20}
                tokenFullName={`${
                  tokenSymbol === 'PHUX' ? '' : marketsData[currentMarket].aTokenPrefix
                }${tokenSymbol.slice(0, 1) === 'w' ? tokenSymbol.slice(1) : tokenSymbol}`}
                key={index}
              />
            }
            className="StakingClaim__rewardRow"
            key={index}
          >
            <Value
              symbol={''}
              value={amount}
              tokenIcon={false}
              tooltipId={tokenSymbol}
              key={index}
            />
          </Row>
        ))}
        <Row title={intl.formatMessage(messages.total)} className="StakingClaim__valueRow">
          <Value
            symbol={intl.formatMessage(messages.usd)}
            value={stakeData.userTotalRewardsInUsd}
            maximumValueDecimals={2}
            tokenIcon={false}
            tooltipId={intl.formatMessage(messages.usd)}
          />
        </Row>

        <ClaimInfoPanel />

        <style jsx={true} global={true}>
          {staticStyles}
        </style>
      </StakeTxConfirmationView>
      <div style={{ marginBottom: '80px' }}></div>
    </>
  );
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  depositInformation: { defaultMessage: 'Deposit Information' },
  approximateBalance: { defaultMessage: 'Approximate balance' },
  depositComposition: { defaultMessage: 'Deposit Composition' },
  borrowingPowerAvailable: { defaultMessage: 'Borrowing power available' },

  borrowInformation: { defaultMessage: 'Borrow Information' },
  youBorrowed: { defaultMessage: 'You borrowed' },
  yourCollateral: { defaultMessage: 'Your collateral' },
  currentLTV: { defaultMessage: 'Current LTV' },
  borrowingPowerUsed: { defaultMessage: 'Borrowing Power Used' },
  borrowComposition: { defaultMessage: 'Borrow Composition' },
  details: { defaultMessage: 'Details' },

  noDeposits: { defaultMessage: 'No deposits yet' },
  connectWallet: { defaultMessage: 'Please connect your wallet' },
});

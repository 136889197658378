import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { defaultMessage: 'No deposits found for your address' },
  description: {
    defaultMessage:
      'We could not detect a deposit from your address. Make sure you’re in the correct market or make a first time deposit',
  },
  orDepositNow: { defaultMessage: 'Or deposit your assets now' },
  depositNow: { defaultMessage: 'Deposit now' },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  description: { defaultMessage: 'Connect your wallet and jump into DeFi' },

  titleBrowserWallet: { defaultMessage: '{walletName} Wallet' },
  noBrowserBrowserWallet: { defaultMessage: 'No browser wallet detected.' },

  continueWithoutWallet: { defaultMessage: 'or continue without wallet' },
  errorCaption: { defaultMessage: 'Error detected' },

  needHelp: { defaultMessage: 'Need help connecting a wallet? {readOurFAQ}' },
  readOurFAQ: { defaultMessage: 'Read our FAQ' },

  disclaimerBottomText: {
    defaultMessage:
      '{disclaimer} Wallets are provided by External Providers and by selecting you agree to Terms of those Providers. Your access to the wallet might be reliant on the External Provider being operational.',
  },
  disclaimer: { defaultMessage: 'Disclaimer:' },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  deposit: { defaultMessage: 'Deposit' },
  withdraw: { defaultMessage: 'Withdraw' },
  borrow: { defaultMessage: 'Borrow' },
  repay: { defaultMessage: 'Repay' },
  swap: { defaultMessage: 'Swap' },
  stake: { defaultMessage: 'Stake' },
  claim: { defaultMessage: 'Claim' },
});

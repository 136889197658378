import { defineMessages } from 'react-intl';

export default defineMessages({
  caption: { defaultMessage: 'Approximate balance' },
  descriptionFirst: {
    defaultMessage:
      'Your aggregated balance shows the approximate value in USD of all the assets you have deposited. It fluctuates based on the evolution of prices.',
  },
  descriptionSecond: {
    defaultMessage:
      'Please note that if your deposits consist of stable-coins the approximate balance in USD could be slightly higher or lower than the stable-coin balance, due to fluctuations in the stable-coin peg.',
  },
});

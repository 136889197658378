import { useState } from 'react';

import {
  UiPoolDataProvider,
  ReservesDataHumanized,
  UserReserveDataHumanized,
} from '@aave/contract-helpers';
import { usePolling } from '../../hooks/use-polling';
import { getProvider } from '../../../helpers/config/markets-and-network-config';
import { CustomChainId } from '../../../ui-config/networks';
import { IUiPoolLimitDataProvider__factory } from '../contracts/factories/IUiPoolLimitDataProvider__factory';

// interval in which the rpc data is refreshed
const POLLING_INTERVAL = 30 * 1000;

export interface ReserveLimits {
  maxGlobalDepositSize: string;
  maxIndividualDepositSize: string;
  minIndividualDepositSize: string;
  maxGlobalBorrowSize: string;
  maxIndividualBorrowSize: string;
  maxBorrowBps: number;
}

export interface PoolDataResponse {
  loading: boolean;
  error: boolean;
  data: {
    reserves?: ReservesDataHumanized;
    reserveLimits?: ReserveLimits[];
    userReserves?: UserReserveDataHumanized[];
  };
  refresh: () => Promise<any>;
}

// Fetch reserve and user incentive data from UiIncentiveDataProvider
export function usePoolData(
  lendingPoolAddressProvider: string,
  chainId: CustomChainId,
  poolDataProviderAddress: string,
  poolLimitDataProviderAddress: string,
  skip: boolean,
  userAddress?: string
): PoolDataResponse {
  const currentAccount: string | undefined = userAddress ? userAddress.toLowerCase() : undefined;
  const [loadingReserves, setLoadingReserves] = useState<boolean>(false);
  const [errorReserves, setErrorReserves] = useState<boolean>(false);
  const [loadingReserveLimits, setLoadingReserveLimits] = useState<boolean>(false);
  const [errorReserveLimits, setErrorReserveLimits] = useState<boolean>(false);
  const [loadingUserReserves, setLoadingUserReserves] = useState<boolean>(false);
  const [errorUserReserves, setErrorUserReserves] = useState<boolean>(false);
  const [reserves, setReserves] = useState<ReservesDataHumanized | undefined>(undefined);
  const [reserveLimits, setReserveLimits] = useState<ReserveLimits[] | undefined>(undefined);
  const [userReserves, setUserReserves] = useState<UserReserveDataHumanized[] | undefined>(
    undefined
  );

  // a work-around is to say if Chain ="941", hardcode [reservesResponse] for now
  // Fetch and format reserve incentive data from UiIncentiveDataProvider contract
  const fetchReserves = async () => {
    const provider = getProvider(chainId);
    const poolDataProviderContract = new UiPoolDataProvider({
      uiPoolDataProviderAddress: poolDataProviderAddress,
      provider,
    });

    try {
      setLoadingReserves(true);
      const reservesResponse = await poolDataProviderContract.getReservesHumanized(
        lendingPoolAddressProvider
      );
      setReserves(reservesResponse);
      setErrorReserves(false);
    } catch (e) {
      console.log('e', e);
      setErrorReserves((e as any).message);
    }
    setLoadingReserves(false);
  };

  const fetchReserveLimits = async () => {
    const dataProviderContract = IUiPoolLimitDataProvider__factory.connect(
      poolLimitDataProviderAddress,
      getProvider(chainId)
    );

    try {
      setLoadingReserveLimits(true);
      const [, limits] = await dataProviderContract.getLimits(lendingPoolAddressProvider);
      setReserveLimits(
        limits.map(
          ({
            maxGlobalDepositSize,
            maxIndividualDepositSize,
            minIndividualDepositSize,
            maxGlobalBorrowSize,
            maxIndividualBorrowSize,
            maxBorrowBps,
          }): ReserveLimits => {
            return {
              maxGlobalDepositSize: maxGlobalDepositSize.toString(),
              maxIndividualDepositSize: maxIndividualDepositSize.toString(),
              minIndividualDepositSize: minIndividualDepositSize.toString(),
              maxGlobalBorrowSize: maxGlobalBorrowSize.toString(),
              maxIndividualBorrowSize: maxIndividualBorrowSize.toString(),
              maxBorrowBps: maxBorrowBps.toNumber(),
            };
          }
        )
      );
      setErrorReserveLimits(false);
    } catch (e) {
      console.log('e', e);
      setErrorReserveLimits((e as any).message);
    }
    setLoadingReserveLimits(false);
  };

  // Fetch and format user incentive data from UiIncentiveDataProvider
  const fetchUserReserves = async () => {
    if (!currentAccount) return;
    const provider = getProvider(chainId);
    const poolDataProviderContract = new UiPoolDataProvider({
      uiPoolDataProviderAddress: poolDataProviderAddress,
      provider,
    });

    try {
      setLoadingUserReserves(true);
      const userReservesResponse: UserReserveDataHumanized[] =
        await poolDataProviderContract.getUserReservesHumanized(
          lendingPoolAddressProvider,
          currentAccount
        );

      setUserReserves(userReservesResponse);
      setErrorUserReserves(false);
    } catch (e) {
      console.log('e', e);
      setErrorUserReserves((e as any).message);
    }
    setLoadingUserReserves(false);
  };

  usePolling(fetchReserves, POLLING_INTERVAL, skip, [skip, poolDataProviderAddress, chainId]);
  usePolling(fetchReserveLimits, POLLING_INTERVAL, skip, [
    skip,
    poolLimitDataProviderAddress,
    chainId,
  ]);
  usePolling(fetchUserReserves, POLLING_INTERVAL, skip, [
    skip,
    poolDataProviderAddress,
    chainId,
    currentAccount,
  ]);

  const loading = loadingReserves || loadingReserveLimits || loadingUserReserves;
  const error = errorReserves || errorReserveLimits || errorUserReserves;
  return {
    loading,
    error,
    data: { reserves, reserveLimits, userReserves },
    refresh: () => {
      return Promise.all([fetchUserReserves(), fetchReserveLimits(), fetchReserves()]);
    },
  };
}

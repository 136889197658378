import { Redirect, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import BigNumber from 'bignumber.js';
import queryString from 'query-string';

import { useStakeDataContext } from '../../../../libs/pool-data-provider/hooks/use-stake-data-context';
import BasicForm from '../../../../components/forms/BasicForm';

import messages from './messages';
import {
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  transactionType,
} from '@aave/contract-helpers';
import { PhiatFeeDistribution__factory } from '../../../../libs/pool-data-provider/contracts/factories/PhiatFeeDistribution__factory';
import { getProvider } from '../../../../helpers/config/markets-and-network-config';

export default function UnstakeAmount() {
  const intl = useIntl();
  const history = useHistory();
  const { chainId, stakeConfig, stakeData, stakingService } = useStakeDataContext();

  if (!stakeData.userCanUnstake) {
    return <Redirect to="/staking" />;
  }

  const handleSubmit = (amount: string) => {
    const query = queryString.stringify({ amount });
    history.push(`/staking/unstake/confirmation?${query}`);
  };

  const convertedAmount = new BigNumber(stakeData.userStakedBalance)
    .multipliedBy(new BigNumber(10).exponentiatedBy(stakeData.stakingTokenPrecision))
    .toFixed(0);
  const handleGetTransactions =
    (userId: string) => async (): Promise<EthereumTransactionTypeExtended[]> => {
      const stakingContract = PhiatFeeDistribution__factory.connect(
        stakeConfig.feeDistribution,
        getProvider(chainId)
      );
      const txCallback: () => Promise<transactionType> = stakingService.generateTxCallback({
        rawTxMethod: async () => stakingContract.populateTransaction.unstake(convertedAmount),
        from: userId,
        gasSurplus: 20,
      });
      return [
        {
          tx: txCallback,
          txType: eEthereumTxType.STAKE_ACTION,
          gas: stakingService.generateTxPriceEstimation([], txCallback),
        },
      ];
    };
  return (
    <BasicForm
      title={intl.formatMessage(messages.caption, {
        asset: stakeConfig.stakingTokenSymbol,
      })}
      description={intl.formatMessage(messages.description)}
      amountFieldTitle={intl.formatMessage(messages.currentlyStaked)}
      maxAmount={stakeData.userStakedBalance}
      currencySymbol={stakeConfig.stakingTokenSymbol}
      onSubmit={handleSubmit}
      submitButtonTitle={intl.formatMessage(messages.unstake)}
      getTransactionData={handleGetTransactions}
    />
  );
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  borrowedDescription: { defaultMessage: 'You borrowed this asset' },
  depositDescription: { defaultMessage: 'You deposited this asset' },
  APYTypeChangeDescription: { defaultMessage: 'You have changed your APY Type for your asset' },
  repaidDescription: { defaultMessage: 'You have made a repayment' },
  withdrawalDescription: { defaultMessage: 'You withdrew an asset to an external address' },
  usageOfCollateralDescription: {
    defaultMessage: 'You have chosen that this asset be used/not be used as collateral',
  },
  liquidationDescription: { defaultMessage: 'Your position has been liquidated' },
  stakedDescription: { defaultMessage: 'You staked {PHIAT}' },
  unstakedDescription: { defaultMessage: 'You unstaked {PHIAT}' },
  unstakeCancelledDescription: { defaultMessage: 'You canceled unstaking {PHIAT}' },
  withdrawnDescription: { defaultMessage: 'You withdrew {PHIAT}' },

  amount: { defaultMessage: 'Amount' },
  liquidationAmount: { defaultMessage: 'Debt covered' },

  date: { defaultMessage: 'Date' },
  asset: { defaultMessage: 'Asset' },
  viewTransaction: { defaultMessage: 'View Transaction' },
  explorer: { defaultMessage: 'Explorer' },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  yourBalanceInPhiat: { defaultMessage: 'Your balance' },
  yourWalletBalance: { defaultMessage: 'Your wallet balance' },
  totalDeposits: { defaultMessage: 'Total deposits' },

  youBorrowed: { defaultMessage: 'You borrowed' },
  totalCollateral: { defaultMessage: 'Total collateral' },
  loanToValue: { defaultMessage: 'Loan to value' },
});

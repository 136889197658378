import React from 'react';
import { useIntl } from 'react-intl';

import { useThemeContext } from '@aave/aave-ui-kit';
import Value from '../../../../components/basic/Value';

import messages from './messages';
import staticStyles from './style';
import { useStakeDataContext } from '../../../../libs/pool-data-provider/hooks/use-stake-data-context';
import Row from '../../../../components/basic/Row';

interface StakingTopPanelProps {
  title: string;
  totalSupply: string;
  totalStakedSupply: string;
  totalStakedPercentage: string;
}

export default function StakingTopPanel({
  title,
  totalSupply,
  totalStakedSupply,
  totalStakedPercentage,
}: StakingTopPanelProps) {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { stakeConfig } = useStakeDataContext();

  const data = [
    {
      title: messages.totalSupply,
      value: totalSupply,
      symbol: stakeConfig.stakingTokenSymbol,
      key: stakeConfig.stakingTokenSymbol + 'total',
    },
    {
      title: messages.totalStakedSupply,
      value: totalStakedSupply,
      symbol: stakeConfig.stakingTokenSymbol,
      key: stakeConfig.stakingTokenSymbol + 'stake',
    },
    {
      title: messages.totalStakedPercentage,
      value: totalStakedPercentage,
      symbol: '%',
      key: '%',
    },
  ];

  return (
    <div className="StakingTopPanel">
      <h3 className="StakingTopPanel__caption">{title}</h3>

      <div className="StakingTopPanel__values">
        {data.map((item) => (
          <div className="StakingTopPanel__value-inner" key={item.key}>
            <p className="StakingTopPanel__value-title">{intl.formatMessage(item.title)}</p>
            <Value
              value={item.value}
              symbol={item.symbol}
              maximumValueDecimals={2}
              minimumValueDecimals={2}
              color="white"
              tokenIcon={item.symbol === 'USD'}
            />
          </div>
        ))}
      </div>
      <div className="StakingTopPanel__values--mobile">
        {data.map((item) => (
          <Row title={intl.formatMessage(item.title)} key={item.key}>
            <Value
              value={item.value}
              symbol={item.symbol}
              maximumValueDecimals={2}
              minimumValueDecimals={2}
              color="white"
              tokenIcon={item.symbol === 'USD'}
            />
          </Row>
        ))}
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true}>{`
        .StakingTopPanel {
          background: ${currentTheme.darkBlue.hex};
          color: ${currentTheme.white.hex};
        }
      `}</style>
    </div>
  );
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  optionTitleLeft: { defaultMessage: 'All' },
  optionTitleRight: { defaultMessage: 'Stable Coins' },
  noDataTitle: { defaultMessage: 'There are no currencies matching the parameters' },

  availableToDeposit: { defaultMessage: 'Available to deposit' },
  myDeposits: { defaultMessage: 'My deposits' },
});

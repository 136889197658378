import { defineMessages } from 'react-intl';

export default defineMessages({
  warningHeader: {
    defaultMessage: 'Enter App',
  },
  warningP1: {
    defaultMessage:
      'The website is hosted and served on the distributed, peer-to-peer IPFS network.',
  },
  warningP2: {
    defaultMessage: 'Alternative links can be found in the {docs}.',
  },
  docs: {
    defaultMessage: 'docs',
  },
  warningP3: {
    defaultMessage: 'By clicking Agree you accept the following terms:',
  },
  warningP4: {
    defaultMessage:
      '1. I am not the person or entities who reside in, are citizens of, are incorporated in, or have a registered office in the United States of America or any Prohibited Localities, similar to {terms}.',
  },
  terms: {
    defaultMessage: 'Token Mint Terms',
  },
  warningP5: {
    defaultMessage:
      '2. I will not in the future access this site or use PHIAT dApp while located within the United States or any Prohibited Localities, as defined in the Terms of Use.',
  },
  warningP6: {
    defaultMessage:
      '3. I am not using, and will not in the future use, a VPN to mask my physical location from a restricted territory.',
  },
  warningP7: {
    defaultMessage:
      '4. I am lawfully permitted to access this site and use PHIAT dApp under the laws of the jurisdiction on which I reside and am located.',
  },
  warningP8: {
    defaultMessage: '5. I understand the risks associated with entering into using PHIAT protocol.',
  },
});

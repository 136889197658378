import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: { defaultMessage: `Withdraw {currencySymbol}` },

  connectWallet: { defaultMessage: 'Please connect a wallet' },
  connectWalletDescription: {
    defaultMessage: 'We couldn’t detect a wallet. Connect a wallet to withdraw.',
  },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  congratulations: { defaultMessage: 'Congrats!' },
  transactionDetails: {
    defaultMessage:
      'These are your transaction details. Make sure to check if this is correct before submitting.',
  },
  successfullyExecuted: { defaultMessage: 'Your action has been successfully executed' },
  nextSteps: { defaultMessage: 'Next steps' },
  approve: { defaultMessage: 'Approve' },
  goBack: { defaultMessage: 'Go back' },

  checkboxText: { defaultMessage: 'Don’t ask me to approve again' },
  goToDashboard: { defaultMessage: 'Go to dashboard' },
  submit: { defaultMessage: 'Submit' },

  errorTitle: { defaultMessage: 'Something went wrong' },
  errorDescription: {
    defaultMessage:
      'It is not possible to complete the transaction at the moment, please try again later.',
  },
  txFailReason: { defaultMessage: 'Transaction failed with the reason: {reason}' },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  error: { defaultMessage: 'Error' },
  submit: { defaultMessage: 'Submit' },
  goBack: { defaultMessage: 'Go back' },

  copyError: { defaultMessage: 'Copy error' },
  copied: { defaultMessage: 'Copied' },
  showError: { defaultMessage: 'Show error' },

  errorReport: { defaultMessage: 'Error report generated' },
  copyErrorAndClose: { defaultMessage: 'Copy error & close' },
});

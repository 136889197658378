import { defineMessages } from 'react-intl';

export default defineMessages({
  markets: { defaultMessage: 'Markets' },
  dashboard: { defaultMessage: 'My dashboard' },
  deposit: { defaultMessage: 'Deposit' },
  borrow: { defaultMessage: 'Borrow' },
  swap: { defaultMessage: 'Swap' },
  staking: { defaultMessage: 'Staking' },
  governance: { defaultMessage: 'Governance' },
});

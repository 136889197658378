import { defineMessages } from 'react-intl';

export default defineMessages({
  switchRightOption: { defaultMessage: 'Borrowings' },
  switchLeftOption: { defaultMessage: 'Deposits' },

  depositInformation: { defaultMessage: 'Deposit Information' },
  approximateBalance: { defaultMessage: 'Approximate balance' },
  depositComposition: { defaultMessage: 'Deposit Composition' },

  borrowInformation: { defaultMessage: 'Borrow Information' },
  youBorrowed: { defaultMessage: 'You borrowed' },
  yourCollateral: { defaultMessage: 'Your collateral' },
  currentLTV: { defaultMessage: 'Current LTV' },
  borrowingPowerUsed: { defaultMessage: 'Borrowing Power Used' },
  borrowComposition: { defaultMessage: 'Borrow Composition' },
  details: { defaultMessage: 'Details' },

  connectWallet: { defaultMessage: 'Please connect your wallet' },
  connectWalletDescription: {
    defaultMessage: 'To see your deposited / borrowed assets, you need to connect your wallet.',
  },
});

import { CustomChainId } from './networks';

export type StakeConfig = {
  dataProvider: string;
  feeDistribution: string;
  stakingToken: string;
  stakingTokenSymbol: string;
  stakingTokenDex: string;
  stakingTokenDexUrl: string;
  phuxToken?: string;
};

export const stakeConfig: Record<string, StakeConfig> = {
  [CustomChainId.plsmainnet]: {
    dataProvider: '0xEABFC5D96e73aDbf93685108A035608859627B9D', // UiStakeDataProvider
    feeDistribution: '0xeAa92F835757a8B3fA4cbCA3Db9D2Ea342651D44',
    stakingToken: '0x96E035ae0905EFaC8F733f133462f971Cfa45dB1', // PHIAT
    stakingTokenSymbol: 'PHIAT',
    stakingTokenDex: 'PHUX',
    stakingTokenDexUrl: 'https://phux.io/#/pulse/trade',
    phuxToken: '0x9663c2d75ffd5F4017310405fCe61720aF45B829',
  },
  [CustomChainId.mainnet]: {
    dataProvider: '0xb897eeA4ec86c52818AD50F22aDc93Cd15C0E2fB', // UiStakeDataProvider
    feeDistribution: '0xA7C43556416C8E9fE11C2D382ba01476D4155c8D',
    stakingToken: '0xE9F721E7419423f11863e83DbD710b5D6127b5b0', // ePhiat
    stakingTokenDex: 'Uniswap',
    stakingTokenDexUrl:
      'https://app.uniswap.org/#/swap?outputCurrency=0xE9F721E7419423f11863e83DbD710b5D6127b5b0',
    stakingTokenSymbol: 'ePhiat',
  },
};

import React from 'react';
import ReactTooltip from 'react-tooltip';

import { useThemeContext } from '@aave/aave-ui-kit';

import TableItem from '../../../../components/BasicAssetsTable/TableItem';
import TableColumn from '../../../../components/BasicTable/TableColumn';
import Value from '../../../../components/basic/Value';
import LiquidityMiningCard from '../../../../components/liquidityMining/LiquidityMiningCard';
import NoData from '../../../../components/basic/NoData';
import { isAssetStable } from '../../../../helpers/config/assets-config';

import { DepositTableItem } from './types';

import staticStyles from './style';

export default function DepositItem({
  id,
  symbol,
  underlyingAsset,
  walletBalance,
  walletBalanceInUSD,
  availableToDeposit,
  availableToDepositInUSD,
  marketLimitTooltip,
  liquidityRate,
  avg30DaysLiquidityRate,
  userId,
  borrowingEnabled,
  isFreezed,
  aincentivesAPR,
}: DepositTableItem) {
  const { currentTheme } = useThemeContext();
  const url = `/deposit/${underlyingAsset}-${id}`;

  return (
    <TableItem symbol={symbol} url={url} isFreezed={isFreezed} darkOnDarkMode={true}>
      <TableColumn>
        {!userId || Number(walletBalance) <= 0 ? (
          <>
            <p data-tip={true} data-for={`wallet-balance-${symbol}`}>
              <NoData color="dark" />
            </p>
            <ReactTooltip
              className="DepositItem__noDataTooltip"
              id={`wallet-balance-${symbol}`}
              effect="solid"
            >
              {marketLimitTooltip}
            </ReactTooltip>
          </>
        ) : (
          <Value
            value={Number(walletBalance)}
            subValue={walletBalanceInUSD}
            maximumSubValueDecimals={2}
            subSymbol="USD"
            maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            tooltipId={`wallet-balance-${symbol}`}
            additionalTooltip={marketLimitTooltip}
            className="DepositItem__value"
          />
        )}
      </TableColumn>

      <TableColumn>
        {!userId || Number(availableToDeposit) <= 0 ? (
          <>
            <p data-tip={true} data-for={`available-to-deposit-${symbol}`}>
              <NoData color="dark" />
            </p>
            <ReactTooltip
              className="DepositItem__noDataTooltip"
              id={`available-to-deposit-${symbol}`}
              effect="solid"
            >
              {marketLimitTooltip}
            </ReactTooltip>
          </>
        ) : (
          <Value
            value={Number(availableToDeposit)}
            subValue={availableToDepositInUSD}
            maximumSubValueDecimals={2}
            subSymbol="USD"
            maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            tooltipId={`available-to-deposit-${symbol}`}
            additionalTooltip={marketLimitTooltip}
            className="DepositItem__value"
          />
        )}
      </TableColumn>

      {!isFreezed && (
        <TableColumn>
          <LiquidityMiningCard
            value={liquidityRate}
            thirtyDaysValue={avg30DaysLiquidityRate}
            liquidityMiningValue={aincentivesAPR}
            symbol={symbol}
            type="deposit"
          />
        </TableColumn>
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .DepositItem__noDataTooltip {
          background: ${currentTheme.darkBlue.hex} !important;
          &:after {
            border-top-color: ${currentTheme.darkBlue.hex} !important;
          }
        }
      `}</style>
    </TableItem>
  );
}

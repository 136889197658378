import { useIntl } from 'react-intl';

import { BasicModal, useThemeContext } from '@aave/aave-ui-kit';
import classNames from 'classnames';

import messages from './messages';
import staticStyles from './style';
import DefaultButton from '../basic/DefaultButton';
import Link from '../basic/Link';

interface AgreementWarningModalProps {
  className?: string;
  isVisible: boolean;
  onAgreePress: () => void;
}

export default function AgreementWarningModal({
  className,
  isVisible,
  onAgreePress,
}: AgreementWarningModalProps) {
  const intl = useIntl();
  const { currentTheme, isCurrentThemeDark } = useThemeContext();

  return (
    <BasicModal
      onBackdropPress={() => undefined}
      isVisible={isVisible}
      withCloseButton={false}
      className={classNames('AgreementWarningModal', className)}
    >
      <div className="AgreementWarningModal__inner">
        <h2>{intl.formatMessage(messages.warningHeader)}</h2>
        <br />
        <p>{intl.formatMessage(messages.warningP1)}</p>
        <br />
        <p>
          {intl.formatMessage(messages.warningP2, {
            docs: (
              <Link
                to={'https://ph-defi.gitbook.io/phiat-protocol/useful-information'}
                className="AgreementWarningModal__link"
                absolute={true}
                inNewWindow={true}
                color="secondary"
              >
                <span>{intl.formatMessage(messages.docs)}</span>
              </Link>
            ),
          })}
        </p>
        <br />
        <p>{intl.formatMessage(messages.warningP3)}</p>
        <br />
        <p>
          {intl.formatMessage(messages.warningP4, {
            terms: (
              <Link
                to={'https://ph-defi.gitbook.io/phiat-protocol/token-minting-terms'}
                className="AgreementWarningModal__link"
                absolute={true}
                inNewWindow={true}
                color="secondary"
              >
                <span>{intl.formatMessage(messages.terms)}</span>
              </Link>
            ),
          })}
        </p>
        <br />
        <p>{intl.formatMessage(messages.warningP5)}</p>
        <br />
        <p>{intl.formatMessage(messages.warningP6)}</p>
        <br />
        <p>{intl.formatMessage(messages.warningP7)}</p>
        <br />
        <p>{intl.formatMessage(messages.warningP8)}</p>
        <br />
        <DefaultButton
          className="AgreementWarningModal__inner-button"
          title={'Agree'}
          mobileBig={true}
          onClick={onAgreePress}
        />
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .AgreementWarningModal {
          color: ${currentTheme.textDarkBlue.hex};
          background: ${currentTheme.whiteElement.hex} !important;
          h2 {
            color: ${isCurrentThemeDark ? currentTheme.textDarkBlue.hex : currentTheme.primary.hex};
          }
          p {
            text-align: left;
          }
        }
      `}</style>
    </BasicModal>
  );
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  firstHelpItemCaption: { defaultMessage: 'Stable Interest' },
  firstHelpItemDescription: {
    defaultMessage:
      'Your interest rate will {stay} for the duration of your loan. Recommended for long-term loan periods and for users who prefer predictability.',
  },
  stayTheSame: { defaultMessage: 'stay the same' },
  secondHelpItemCaption: { defaultMessage: 'Variable Interest' },
  secondHelpItemDescription: {
    defaultMessage:
      'Your interest rate will {fluctuate} based on the market. Recommended for short-term loans.',
  },
  fluctuate: { defaultMessage: 'fluctuate' },
});

import { calculateAverageRate, normalize } from '@aave/protocol-js';
import { useEffect, useState } from 'react';
import { RateHistoryDocument, RateHistoryQuery } from '../graphql';
import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import { useProtocolDataContext } from '../../protocol-data-provider';

export type FormattedReserveHistoryItem = {
  timestamp: number;
  liquidityRate: number;
  utilizationRate: number;
  stableBorrowRate: number;
  variableBorrowRate: number;
};

const TIMEFRAME = 3600 * 24 * 7;
const INTERVAL = 5 * 60;
// const INSERT_LIMIT = 3000;

function convertRawDataToHistory(rawData: RateHistoryQuery) {
  const data: FormattedReserveHistoryItem[] = [];
  const rawHistory = rawData.reserveParamsHistoryItems;
  if (rawHistory.length === 0) {
    return data;
  }

  let previousChange: typeof rawHistory[0] | undefined = undefined;
  let previousFrom: typeof rawHistory[0] | undefined = undefined;
  let currentTimestamp = Math.floor(rawHistory[0].timestamp / INTERVAL) * INTERVAL + INTERVAL;
  for (let i = 0; i < rawHistory.length - 1; i += 1) {
    if (rawHistory[i + 1].timestamp <= currentTimestamp) {
      // skip till the index is the closest we can get
    } else {
      const from = rawHistory[i];
      const to = rawHistory.find(
        // eslint-disable-next-line no-loop-func
        (history) => history.timestamp >= currentTimestamp + INTERVAL
      );
      if (!to) {
        break;
      }
      let toChange = rawHistory.find(
        // eslint-disable-next-line no-loop-func
        (history) =>
          history.timestamp > from.timestamp && history.liquidityIndex !== from.liquidityIndex
      );
      if (
        toChange &&
        to.timestamp > toChange.timestamp &&
        to.liquidityIndex !== toChange.liquidityIndex
      ) {
        // use earlier timestamp for calculation to not understate APR
        toChange = to;
      }
      let liquidityRate = data.length === 0 ? 0 : data[data.length - 1].liquidityRate;
      let variableBorrowRate = data.length === 0 ? 0 : data[data.length - 1].variableBorrowRate;
      if (
        toChange &&
        (!previousFrom || (previousFrom && from.liquidityIndex !== previousFrom.liquidityIndex))
      ) {
        // no need to update liquidity rate and variable borrow rate if previous from and current from have same index
        let fromChange = from;
        if (
          previousChange &&
          previousChange.timestamp < from.timestamp &&
          previousChange.liquidityIndex === from.liquidityIndex
        ) {
          // use earlier timestamp for calculation to not overstate APR
          fromChange = previousChange;
        }
        liquidityRate = parseFloat(
          calculateAverageRate(
            fromChange.liquidityIndex,
            toChange.liquidityIndex,
            fromChange.timestamp,
            toChange.timestamp
          )
        );
        variableBorrowRate = parseFloat(
          calculateAverageRate(
            fromChange.variableBorrowIndex,
            toChange.variableBorrowIndex,
            fromChange.timestamp,
            toChange.timestamp
          )
        );
        previousChange = toChange;
        // console.log(i, from, to, fromChange, toChange, liquidityRate, variableBorrowRate);
      }
      const { utilizationRate, stableBorrowRate: stableBorrowRateRaw } = from;
      const stableBorrowRate = parseFloat(normalize(stableBorrowRateRaw, 27));
      for (let timestamp = currentTimestamp; timestamp <= to.timestamp; timestamp += INTERVAL) {
        data.push({
          timestamp: timestamp,
          liquidityRate: liquidityRate,
          utilizationRate: utilizationRate,
          variableBorrowRate: variableBorrowRate,
          stableBorrowRate: stableBorrowRate,
        });
      }
      if (data.length > 0) {
        currentTimestamp = data[data.length - 1].timestamp;
      }
      currentTimestamp += INTERVAL;
      previousFrom = from;
    }
  }
  return data;
}

export function useReserveRatesHistory(reserveId: string) {
  const { networkConfig } = useProtocolDataContext();
  const from = Math.floor(new Date().getTime() / 1000) - TIMEFRAME;

  const [loading, setLoading] = useState(networkConfig.protocolDataUrl !== '');
  const [data, setData] = useState<FormattedReserveHistoryItem[]>([]);

  if (networkConfig.protocolDataUrl !== '') {
    useEffect(() => {
      const client = new GraphQLClient(networkConfig.protocolDataUrl);
      try {
        client
          .rawRequest<RateHistoryQuery>(print(RateHistoryDocument), {
            id: reserveId,
            first: 1000,
            from: from,
          })
          .then(({ data: rawData }) => {
            if (rawData && rawData.reserveParamsHistoryItems.length > 0) {
              setData(convertRawDataToHistory(rawData));
            }
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      } catch (_) {
        setLoading(false);
      }
    }, [reserveId]);
  }

  return {
    loading,
    data,
  };
}

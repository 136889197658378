import { Switch, Route } from 'react-router-dom';

import StakingWrapper from './components/StakingWrapper';
import StakingMain from './screens/StakingMain';
import StakeWithApprovalConfirmation from './screens/StakeWithApprovalConfirmation';

import UnstakeAmount from './screens/UnstakeAmount';
import UnstakeConfirmation from './screens/UnstakeConfirmation';

import CancelConfirmation from './screens/CancelConfirmation';
import WithdrawConfirmation from './screens/WithdrawConfirmation';

import ClaimConfirmation from './screens/ClaimConfirmation';

import { stakeConfig } from '../../ui-config';
import ErrorPage from '../../components/ErrorPage';

export default function Staking() {
  if (!stakeConfig) {
    return <ErrorPage title="Stake was not configured" />;
  }
  return (
    <StakingWrapper>
      <Switch>
        <Route exact={true} path="/staking" component={StakingMain} />
        <Route
          exact={true}
          path="/staking/confirmation"
          component={StakeWithApprovalConfirmation}
        />

        <Route exact={true} path="/staking/unstake" component={UnstakeAmount} />
        <Route exact={true} path="/staking/unstake/confirmation" component={UnstakeConfirmation} />

        <Route exact={true} path="/staking/cancel/confirmation" component={CancelConfirmation} />

        <Route
          exact={true}
          path="/staking/withdraw/confirmation"
          component={WithdrawConfirmation}
        />

        <Route exact={true} path="/staking/claim/confirmation" component={ClaimConfirmation} />
      </Switch>
    </StakingWrapper>
  );
}

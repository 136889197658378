import { defineMessages } from 'react-intl';

export default defineMessages({
  networkShortName: { defaultMessage: '{name} Network' },
  history: { defaultMessage: 'History' },
  changeAddress: { defaultMessage: 'Change address' },
  disconnect: { defaultMessage: 'Disconnect' },
  // eslint-disable-next-line no-template-curly-in-string
  addPhiat: { defaultMessage: 'Add ${PHIAT} to Wallet' },
});

import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';

import { useCurrentTimestamp } from '../hooks/use-current-timestamp';
import { useStaticPoolDataContext } from './static-pool-data-provider';
import {
  ComputedUserReserve,
  formatReserve,
  FormatReserveResponse,
  formatUserSummary,
  FormatUserSummaryResponse,
  normalize,
  normalizeBN,
  valueToBigNumber,
} from '@aave/math-utils';
import { ReserveLimits } from '../hooks/use-pool-data';

interface ComputedReserveLimits {
  maxGlobalDepositSize: string;
  globalDepositSizeAvailable: string;
  hasMaxGlobalDepositSizeLimit: boolean;

  maxIndividualDepositSize: string;
  hasMaxIndividualDepositSizeLimit: boolean;

  minIndividualDepositSize: string;
  hasMinIndividualDepositSizeLimit: boolean;

  rawMaxGlobalBorrowSize: string; // original limit
  maxGlobalBorrowSize: string; // taking into consideration maxBorrowBps
  globalBorrowSizeAvailable: string;
  hasMaxGlobalBorrowSizeLimit: boolean;

  maxIndividualBorrowSize: string;
  hasMaxIndividualBorrowSizeLimit: boolean;

  maxBorrowBps: number;
}

interface ComputedUserReserveLimits {
  individualDepositSizeAvailable: string;
  minIndividualDepositSize: string;
  individualBorrowSizeAvailable: string;
}

export interface ComputedReserveData extends FormatReserveResponse, ComputedReserveLimits {
  id: string;
  underlyingAsset: string;
  name: string;
  symbol: string;
  decimals: number;
  usageAsCollateralEnabled: boolean;
  borrowingEnabled: boolean;
  stableBorrowRateEnabled: boolean;
  isActive: boolean;
  isFrozen: boolean;
  aTokenAddress: string;
  stableDebtTokenAddress: string;
  variableDebtTokenAddress: string;
  priceInMarketReferenceCurrency: string;
  avg30DaysLiquidityRate?: string;
  avg30DaysVariableBorrowRate?: string;
}

export interface ComputedUserReserveWithLimits
  extends ComputedUserReserve,
    ComputedUserReserveLimits {}

export interface UserSummary extends FormatUserSummaryResponse {
  id: string;
  userReservesDataWithLimits: ComputedUserReserveWithLimits[];
}

export interface DynamicPoolDataContextData {
  reserves: ComputedReserveData[];
  user?: UserSummary;
}

function formatReserveLimits(
  formattedReserve: FormatReserveResponse,
  reserveLimits: ReserveLimits,
  decimals: number
): ComputedReserveLimits {
  const { totalLiquidity, totalDebt } = formattedReserve;
  const {
    maxGlobalDepositSize,
    maxIndividualDepositSize,
    minIndividualDepositSize,
    maxGlobalBorrowSize,
    maxIndividualBorrowSize,
    maxBorrowBps,
  } = reserveLimits;

  const maxGlobalDepositSizeInReserveCurrency = normalizeBN(maxGlobalDepositSize, decimals);
  let globalDepositSizeAvailable = maxGlobalDepositSizeInReserveCurrency.minus(totalLiquidity);

  const maxIndividualDepositSizeInReserveCurrency = normalizeBN(maxIndividualDepositSize, decimals);

  const minIndividualDepositSizeInReserveCurrency = normalizeBN(minIndividualDepositSize, decimals);

  const rawMaxGlobalBorrowSizeInReserveCurrency = normalizeBN(maxGlobalBorrowSize, decimals); // fixed limit
  let maxGlobalBorrowSizeInReserveCurrency = rawMaxGlobalBorrowSizeInReserveCurrency;
  if (maxBorrowBps > 0) {
    // percentage based limit
    const maxBorrowSizeInReserveCurrency = valueToBigNumber(totalLiquidity)
      .multipliedBy(maxBorrowBps)
      .dividedBy(10000);
    if (
      maxGlobalBorrowSizeInReserveCurrency.gt(maxBorrowSizeInReserveCurrency) ||
      maxGlobalBorrowSize === '0'
    ) {
      // replace fixed limit with percentage based limit
      maxGlobalBorrowSizeInReserveCurrency = maxBorrowSizeInReserveCurrency;
    }
  } else if (
    maxGlobalBorrowSizeInReserveCurrency.gt(totalLiquidity) ||
    maxGlobalBorrowSize === '0'
  ) {
    // replace fixed limit with available liquidity
    maxGlobalBorrowSizeInReserveCurrency = valueToBigNumber(totalLiquidity);
  }
  const globalBorrowSizeAvailable = maxGlobalBorrowSizeInReserveCurrency.minus(totalDebt);

  const maxIndividualBorrowSizeInReserveCurrency = normalizeBN(maxIndividualBorrowSize, decimals);

  return {
    maxGlobalDepositSize: maxGlobalDepositSizeInReserveCurrency.toFixed(decimals),
    globalDepositSizeAvailable: globalDepositSizeAvailable.lt(0)
      ? '0'
      : globalDepositSizeAvailable.toFixed(decimals),
    hasMaxGlobalDepositSizeLimit: maxGlobalDepositSize !== '0',

    maxIndividualDepositSize: maxIndividualDepositSizeInReserveCurrency.toFixed(decimals),
    hasMaxIndividualDepositSizeLimit: maxIndividualDepositSize !== '0',

    minIndividualDepositSize: minIndividualDepositSizeInReserveCurrency.toFixed(decimals),
    hasMinIndividualDepositSizeLimit: minIndividualDepositSize !== '0',

    rawMaxGlobalBorrowSize: rawMaxGlobalBorrowSizeInReserveCurrency.toFixed(decimals),
    maxGlobalBorrowSize: maxGlobalBorrowSizeInReserveCurrency.toFixed(decimals),
    globalBorrowSizeAvailable: globalBorrowSizeAvailable.lt(0)
      ? '0'
      : globalBorrowSizeAvailable.toFixed(decimals),
    hasMaxGlobalBorrowSizeLimit: maxGlobalBorrowSize !== '0' || maxBorrowBps > 0,

    maxIndividualBorrowSize: maxIndividualBorrowSizeInReserveCurrency.toFixed(decimals),
    hasMaxIndividualBorrowSizeLimit: maxIndividualBorrowSize !== '0',

    maxBorrowBps: maxBorrowBps,
  };
}

function formatUserReserveLimits(
  userReserveData: ComputedUserReserve,
  reserveLimits: ReserveLimits,
  marketRefCurrencyDecimals: number
): ComputedUserReserveLimits {
  const { reserve, underlyingBalance, totalBorrows } = userReserveData;
  const { decimals } = reserve;
  const { maxIndividualDepositSize, minIndividualDepositSize, maxIndividualBorrowSize } =
    reserveLimits;

  const maxIndividualDepositSizeInReserveCurrency = normalizeBN(maxIndividualDepositSize, decimals);
  const individualDepositSizeAvailable =
    maxIndividualDepositSizeInReserveCurrency.minus(underlyingBalance);

  const minIndividualDepositSizeInReserveCurrency = normalizeBN(minIndividualDepositSize, decimals);

  const maxIndividualBorrowSizeInReserveCurrency = normalizeBN(maxIndividualBorrowSize, decimals);
  const individualBorrowSizeAvailable =
    maxIndividualBorrowSizeInReserveCurrency.minus(totalBorrows);

  return {
    individualDepositSizeAvailable: individualDepositSizeAvailable.lt(0)
      ? '0'
      : individualDepositSizeAvailable.toFixed(marketRefCurrencyDecimals),
    minIndividualDepositSize: minIndividualDepositSizeInReserveCurrency.gt(underlyingBalance)
      ? minIndividualDepositSizeInReserveCurrency
          .minus(underlyingBalance)
          .toFixed(marketRefCurrencyDecimals)
      : '0',
    individualBorrowSizeAvailable: individualBorrowSizeAvailable.lt(0)
      ? '0'
      : individualBorrowSizeAvailable.toFixed(marketRefCurrencyDecimals),
  };
}

const DynamicPoolDataContext = React.createContext({} as DynamicPoolDataContextData);

export function DynamicPoolDataProvider({ children }: PropsWithChildren<{}>) {
  const {
    rawReserves,
    rawUserReserves,
    rawReserveLimits,
    userId,
    marketRefCurrencyDecimals,
    marketRefPriceInUsd,
  } = useStaticPoolDataContext();
  const currentTimestamp = useCurrentTimestamp(1);
  const [lastAvgRatesUpdateTimestamp, setLastAvgRatesUpdateTimestamp] = useState(currentTimestamp);

  useEffect(() => {
    if (currentTimestamp > lastAvgRatesUpdateTimestamp + 1000 * 60 * 5) {
      setLastAvgRatesUpdateTimestamp(currentTimestamp);
    }
  }, [currentTimestamp, lastAvgRatesUpdateTimestamp]);

  const computedUserData =
    userId && rawUserReserves
      ? formatUserSummary({
          currentTimestamp,
          marketRefPriceInUsd,
          marketRefCurrencyDecimals,
          rawUserReserves: rawUserReserves,
        })
      : undefined;
  let computedUserDataWithLimits: ComputedUserReserveWithLimits[] = [];
  if (
    userId &&
    computedUserData &&
    rawReserveLimits.length > 0 &&
    rawReserveLimits.length === computedUserData.userReservesData.length
  ) {
    for (let i = 0; i < rawReserveLimits.length; i++) {
      const userReserveData = computedUserData.userReservesData[i];
      const formattedUserReserveLimits = formatUserReserveLimits(
        userReserveData,
        rawReserveLimits[i],
        marketRefCurrencyDecimals
      );
      computedUserDataWithLimits.push({
        ...userReserveData,
        ...formattedUserReserveLimits,
      });
    }
  }

  const formattedPoolReserves: ComputedReserveData[] = [];
  if (
    rawReserves &&
    rawReserveLimits.length === rawReserves.length &&
    rawReserveLimits.length > 0
  ) {
    for (let i = 0; i < rawReserves.length; i++) {
      const reserve = rawReserves[i];
      const formattedReserve = formatReserve({
        reserve,
        currentTimestamp,
      });
      const priceInMarketReferenceCurrency = normalize(
        reserve.priceInMarketReferenceCurrency,
        marketRefCurrencyDecimals
      );
      const reserveLimits = formatReserveLimits(
        formattedReserve,
        rawReserveLimits[i],
        reserve.decimals
      );
      const fullReserve: ComputedReserveData = {
        ...reserve,
        ...formattedReserve,
        ...reserveLimits,
        priceInMarketReferenceCurrency: priceInMarketReferenceCurrency,
      };
      formattedPoolReserves.push(fullReserve);
    }
  }

  let userSummary: UserSummary | undefined = undefined;
  if (computedUserData && userId && computedUserDataWithLimits.length > 0) {
    userSummary = {
      id: userId,
      userReservesDataWithLimits: computedUserDataWithLimits,
      ...computedUserData,
    };
  }
  return (
    <DynamicPoolDataContext.Provider
      value={{
        user: userSummary,
        reserves: formattedPoolReserves,
      }}
    >
      {children}
    </DynamicPoolDataContext.Provider>
  );
}

export const useDynamicPoolDataContext = () => useContext(DynamicPoolDataContext);

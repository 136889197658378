import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: { defaultMessage: 'History' },

  noDataTitle: { defaultMessage: 'No history yet' },
  noDataDescription: {
    defaultMessage:
      'There will be a list of all the transactions you’ve made. For now it’s empty until you perform your first action.',
  },
  errorNoDataDescription: {
    defaultMessage:
      'Sorry, we’re having trouble retrieving your history at the moment. Please try again later. We apologize for any inconvenience.',
  },
  deposit: { defaultMessage: 'Deposit now' },
  stake: { defaultMessage: 'Stake now' },

  infoDescription: {
    defaultMessage: 'Can’t see your history? You may not have taken any action yet.',
  },
});

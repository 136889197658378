import { defineMessages } from 'react-intl';

export default defineMessages({
  liquidationOverview: { defaultMessage: 'Liquidation Overview' },
  liquidationOverviewDescription: {
    defaultMessage: 'Details about your Loan to Value (LTV) ratio and liquidation.',
  },

  currentLTV: { defaultMessage: 'Current LTV' },
  maximumLTV: { defaultMessage: 'Maximum LTV' },
  liquidationThreshold: { defaultMessage: 'Liquidation threshold' },
  currentAssetPrice: { defaultMessage: 'Current asset price' },
  assetLiquidationPrice: { defaultMessage: 'Asset Liquidation price' },

  collateral: { defaultMessage: 'Collateral' },
  borrow: { defaultMessage: 'Borrow' },
});

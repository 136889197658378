import { BaseNetworkConfig } from '../helpers/config/types';

export enum CustomChainId {
  plsmainnet = 369,
  mainnet = 1,
}

export const networkConfigs: Record<string, BaseNetworkConfig> = {
  [CustomChainId.plsmainnet]: {
    name: 'PulseChain',
    publicJsonRPCUrl: ['https://rpc.pulsechain.com'],
    addresses: {
      walletBalanceProvider: '0x888b42cC22f83Af2F85ae14338E403f52d1aF556',
      uiPoolDataProvider: '0x8355fcC7CC55815c1Fa2E562B0a216d9996D56a8',
      uiPoolLimitDataProvider: '0x66574E90cd0b55ed458E396Fe1E1e63f8a1100a0',
    },
    protocolDataUrl: 'https://sub5.phatty.io/subgraphs/name/phiat',
    baseAsset: 'PLS',
    baseAssetWrappedAddress: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
    gasReserve: '2000',
    // incentives hardcoded information
    rewardTokenSymbol: '',
    rewardTokenAddress: '',
    rewardTokenDecimals: 18,
    explorerLink: 'https://otter.pulsechain.com',
    rpcOnly: true,
  },
  [CustomChainId.mainnet]: {
    name: 'Ethereum mainnet',
    publicJsonRPCUrl: [
      'https://rpc.ankr.com/eth',
      'https://ethereum.publicnode.com',
      //'https://cloudflare-eth.com/',
      // 'https://mainnet.infura.io/v3/e562ddd4a82c45a8aefbff23ce885b03',
      // 'https://mainnet.infura.io/v3/9c652c43e40c4b3a9c4798444017a5d9',
      // 'https://nodes.mewapi.io/rpc/eth',
    ],
    addresses: {
      walletBalanceProvider: '0xC3BbBe26944B272Ae4218174D56A2b40AaE712dE',
      uiPoolDataProvider: '0x86a07f2D483e8867438806329cf0a950C3b229FC',
      uiPoolLimitDataProvider: '0xFA597927c258B08d7f46d153283FF5685901068f',
    },
    protocolDataUrl: 'https://api.studio.thegraph.com/proxy/45156/ephiat-sub-01/v0.0.2/',
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    gasReserve: '0.02',
    // incentives hardcoded information
    rewardTokenSymbol: '',
    rewardTokenAddress: '',
    rewardTokenDecimals: 18,
    explorerLink: 'https://etherscan.io',
    rpcOnly: true,
  },
} as const;

import { defineMessages } from 'react-intl';

export default defineMessages({
  nothingBorrowed: { defaultMessage: 'Nothing borrowed yet' },
  nothingBorrowedDescription: {
    defaultMessage:
      'There will be a list of all the assets you have borrowed. For now, it’s empty since you have not borrowed.',
  },
  borrowNow: { defaultMessage: 'Borrow now' },
});

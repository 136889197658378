import { defineMessages } from 'react-intl';

export default defineMessages({
  marketTooltipP1V1: {
    defaultMessage:
      'For risk mitigation, we have limited the amount of {asset} that may be deposited to a maximum of {maxGlobalDepositSize}, and will allow a maximum of {maxBorrowBps}% of total deposits or {maxGlobalBorrowSize} {asset} (whichever is lower) to be borrowed in total.',
  },
  marketTooltipP1V2: {
    defaultMessage:
      'For risk mitigation, we have limited the amount of {asset} that may be borrowed to a maximum of {maxBorrowBps}% of total deposits.',
  },
  marketTooltipP2: {
    defaultMessage:
      'We have also limited each individual wallet to a range of {minIndividualDepositSize} - {maxIndividualDepositSize} {asset} for total deposit, and a maximum of {maxIndividualBorrowSize} {asset} for total borrowing.',
  },
  marketToolTipP3: {
    defaultMessage: 'Through time these limits will be adjusted based on market conditions.',
  },
});

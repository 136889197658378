import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ETH_DECIMALS, normalize, valueToBigNumber } from '@aave/protocol-js';
import queryString from 'query-string';

import { unPrefixSymbol, useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import {
  BorrowRateMode,
  StakingHistoryQuery,
  UserHistoryQuery,
  useStakingHistoryQuery,
  useUserHistoryQuery,
} from '../../../../libs/pool-data-provider/graphql';
import Preloader from '../../../../components/basic/Preloader';
import Pagination from '../../../../components/basic/Pagination';
import ScreenWrapper from '../../../../components/wrappers/ScreenWrapper';
import NoDataPanelWithInfo from '../../../../components/NoDataPanelWithInfo';
import HistoryContent from '../../components/HistoryContent';

import messages from './messages';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import LabeledSwitcher from '../../../../components/basic/LabeledSwitcher';
import toggleLocalStorageClick from '../../../../helpers/toggle-local-storage-click';
import { stakeConfig } from '../../../../ui-config';

const ITEMS_PER_PAGE = 50;

export default function History() {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();
  const { currentMarketData, networkConfig } = useProtocolDataContext();
  const { marketRefPriceInUsd, userId, rawReserves } = useStaticPoolDataContext();
  const [isStaking, setIsStaking] = useState(localStorage.getItem('historyIsStaking') === 'true');
  const query = queryString.parse(location.search);
  const page = query.page ? Number(query.page) : 0;

  const {
    data: poolData,
    loading: poolLoading,
    error: poolError,
  } = networkConfig.protocolDataUrl === ''
    ? {
        data: undefined,
        loading: false,
        error: true,
      }
    : useUserHistoryQuery({
        skip: !userId,
        pollInterval: 60 * 1000,
        variables: {
          id: userId?.toLowerCase() || '',
          pool: currentMarketData.addresses.LENDING_POOL_ADDRESS_PROVIDER.toLowerCase(),
          skip: page * ITEMS_PER_PAGE,
          first: ITEMS_PER_PAGE,
        },
      });
  const {
    data: stakingData,
    loading: stakingLoading,
    error: stakingError,
  } = networkConfig.protocolDataUrl === ''
    ? {
        data: undefined,
        loading: false,
        error: true,
      }
    : useStakingHistoryQuery({
        skip: !userId,
        pollInterval: 60 * 1000,
        variables: {
          id: userId?.toLowerCase() || '',
          skip: page * ITEMS_PER_PAGE,
          first: ITEMS_PER_PAGE,
        },
      });

  const handleLabelChange = () => {
    toggleLocalStorageClick(isStaking, setIsStaking, 'historyIsStaking');
    const newPage = localStorage.getItem('historyLabelPage') || '0';
    localStorage.setItem('historyLabelPage', page.toString());
    history.push(`${location.pathname}?${queryString.stringify({ ...query, page: newPage })}`);
  };

  const handlePageChange = (previous: boolean) => {
    let nextPage = page;
    if (previous && page > 0) {
      nextPage -= 1;
    } else if (
      !previous &&
      (isStaking ? stakingData?.phiatStakings.length : poolData?.userTransactions.length) ===
        ITEMS_PER_PAGE
    ) {
      nextPage += 1;
    }
    if (nextPage !== page) {
      history.push(
        `${location.pathname}?${queryString.stringify({ ...query, page: nextPage.toString() })}`
      );
    }
  };

  const historyFormattedData = isStaking
    ? stakingData
      ? stakingData.phiatStakings.map((historyItem: StakingHistoryQuery['phiatStakings'][0]) => {
          return {
            type: historyItem.action as 'Staked' | 'Unstaked' | 'Withdrawn' | 'UnstakeCancelled',
            date: historyItem.timestamp,
            amount: normalize(valueToBigNumber(historyItem.amount), 18),
            symbol: stakeConfig[currentMarketData.chainId].stakingTokenSymbol,
            transactionLink: networkConfig.explorerLinkBuilder({ tx: historyItem.txHash }),
          };
        })
      : []
    : poolData
    ? poolData.userTransactions.map((historyItem: UserHistoryQuery['userTransactions'][0]) => {
        let amountDecimals: number = 0;
        let amount: number | string = 0;
        let symbol: string = '';
        let borrowRate: number = 0;
        let borrowRateMode: BorrowRateMode | undefined = undefined;
        let condition: boolean | undefined = undefined;
        let collateralDecimals: number = 0;
        let collateralAmount: number | string = 0;
        let collateralAmountSymbol: string = '';
        let reserveETHPrice: number | string | undefined = undefined;

        // help functions
        const amountNormalize = (amount: number, decimals: number) =>
          normalize(valueToBigNumber(amount), decimals);
        const ethPrice = (symbol: string) =>
          normalize(
            rawReserves.find((reserve) => reserve.symbol === symbol)
              ?.priceInMarketReferenceCurrency || '0',
            ETH_DECIMALS
          );

        if (
          historyItem.__typename === 'Deposit' ||
          historyItem.__typename === 'Borrow' ||
          historyItem.__typename === 'Repay' ||
          historyItem.__typename === 'RedeemUnderlying' ||
          historyItem.__typename === 'Swap' ||
          historyItem.__typename === 'UsageAsCollateral'
        ) {
          symbol = unPrefixSymbol(historyItem.reserve.symbol, currentMarketData.aTokenPrefix);
        }

        if (
          historyItem.__typename === 'Deposit' ||
          historyItem.__typename === 'Borrow' ||
          historyItem.__typename === 'Repay' ||
          historyItem.__typename === 'RedeemUnderlying'
        ) {
          reserveETHPrice = ethPrice(symbol);
        }

        if (
          historyItem.__typename === 'Deposit' ||
          historyItem.__typename === 'Borrow' ||
          historyItem.__typename === 'RedeemUnderlying'
        ) {
          amountDecimals = historyItem.reserve.decimals;
          amount = amountNormalize(historyItem.amount, amountDecimals);
        }

        switch (historyItem.__typename) {
          case 'Borrow':
            borrowRate = valueToBigNumber(normalize(historyItem.borrowRate, 27)).toNumber();
            borrowRateMode = historyItem.borrowRateMode;
            break;

          case 'Repay':
            amountDecimals = historyItem.reserve.decimals;
            amount = amountNormalize(historyItem.amount, amountDecimals);
            break;

          case 'Swap':
            condition = historyItem.borrowRateModeFrom === BorrowRateMode.Variable;
            break;

          case 'UsageAsCollateral':
            condition = historyItem.fromState;
            break;

          case 'LiquidationCall':
            amountDecimals = historyItem.principalReserve.decimals;
            amount = amountNormalize(historyItem.principalAmount, amountDecimals);
            symbol = unPrefixSymbol(
              historyItem.principalReserve.symbol,
              currentMarketData.aTokenPrefix
            );
            collateralDecimals = historyItem.collateralReserve.decimals;
            collateralAmount = amountNormalize(historyItem.collateralAmount, collateralDecimals);
            collateralAmountSymbol = historyItem.collateralReserve.symbol;
            reserveETHPrice = ethPrice(symbol);
            break;
        }

        const amountInUsd =
          amount && reserveETHPrice
            ? valueToBigNumber(amount)
                .multipliedBy(reserveETHPrice)
                .multipliedBy(marketRefPriceInUsd)
            : undefined;

        return {
          type: historyItem.__typename,
          date: historyItem.timestamp,
          amount,
          amountInUsd: amount && amountInUsd && amountInUsd.toNumber(),
          symbol,
          borrowRate,
          borrowRateMode,
          condition,
          collateralAmount,
          collateralAmountSymbol,
          transactionLink: networkConfig.explorerLinkBuilder({ tx: historyItem.id.split(':')[0] }),
        };
      })
    : [];

  return (
    <ScreenWrapper
      pageTitle={intl.formatMessage(messages.pageTitle)}
      isTitleOnDesktop={true}
      withMobileGrayBg={true}
    >
      <div className="Markets__price-switcher">
        <LabeledSwitcher
          value={isStaking}
          leftOption="Pool"
          rightOption="Staking"
          onToggle={handleLabelChange}
        />
      </div>

      {(isStaking ? stakingLoading : poolLoading) ? (
        <Preloader withText={true} />
      ) : !!historyFormattedData.length ? (
        <>
          <HistoryContent data={historyFormattedData} />

          {!(
            page === 0 &&
            (isStaking
              ? stakingData && stakingData.phiatStakings.length < ITEMS_PER_PAGE
              : poolData && poolData.userTransactions.length < ITEMS_PER_PAGE)
          ) && (
            <Pagination
              page={page}
              pageChange={handlePageChange}
              nextButtonDisabled={
                isStaking
                  ? stakingData && stakingData.phiatStakings.length < ITEMS_PER_PAGE
                  : poolData && poolData.userTransactions.length < ITEMS_PER_PAGE
              }
            />
          )}
        </>
      ) : (
        <NoDataPanelWithInfo
          title={intl.formatMessage(messages.noDataTitle)}
          description={intl.formatMessage(
            (isStaking ? stakingError : poolError)
              ? messages.errorNoDataDescription
              : messages.noDataDescription
          )}
          buttonTitle={intl.formatMessage(isStaking ? messages.stake : messages.deposit)}
          infoTextDescription={
            (isStaking ? stakingError : poolError)
              ? undefined
              : intl.formatMessage(messages.infoDescription)
          }
          linkTo={isStaking ? '/staking' : '/deposit'}
        />
      )}
    </ScreenWrapper>
  );
}

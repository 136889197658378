import { defineMessages } from 'react-intl';

export default defineMessages({
  errorDescriptionFirst: {
    defaultMessage:
      'This transaction will fail. Please make sure you have enough {asset} and funds in the connected wallet for the transaction.',
  },
  errorDescriptionSecond: {
    defaultMessage:
      'For assistance you can reach the community on {telegram}. Please save the error report as reference',
  },
});

import React from 'react';
import { useIntl } from 'react-intl';

import { useStakeDataContext } from '../../../libs/pool-data-provider/hooks/use-stake-data-context';
import HelpModalWrapper from '../HelpModalWrapper';
import { HelpModalProps } from '../types';

import messages from './messages';

export default function StakeHelpModal({
  text,
  iconSize,
  className,
  onWhiteBackground,
}: HelpModalProps) {
  const intl = useIntl();
  const { stakeConfig } = useStakeDataContext();
  return (
    <HelpModalWrapper
      text={text}
      iconSize={iconSize}
      className={className}
      caption={intl.formatMessage(messages.caption)}
      onWhiteBackground={onWhiteBackground}
      description={
        <>
          <span style={{ display: 'inline-block', marginBottom: 15 }}>
            {intl.formatMessage(messages.descriptionFirst, {
              asset: <strong>{stakeConfig.stakingTokenSymbol}</strong>,
            })}
          </span>
          <span style={{ display: 'inline-block', marginBottom: 15 }}>
            {intl.formatMessage(messages.descriptionSecond, {
              asset: <strong>{stakeConfig.stakingTokenSymbol}</strong>,
            })}
          </span>
          <span style={{ display: 'inline-block' }}>
            {intl.formatMessage(messages.descriptionThird, {
              asset: <strong>{stakeConfig.stakingTokenSymbol}</strong>,
            })}
          </span>
        </>
      }
      lightWeight={true}
    />
  );
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: { defaultMessage: 'Borrow {currencySymbol}' },

  firstColumnTitle: { defaultMessage: 'You borrowed' },
  secondColumnTitle: { defaultMessage: 'Total collateral' },
  thirdColumnTitle: { defaultMessage: 'Loan to value' },
  graphDotStable: { defaultMessage: 'Stable' },
  graphDotVariable: { defaultMessage: 'Variable' },
});

import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .BorrowAmount__marketLimitTooltip {
    p {
      margin-bottom: 8px;
      text-align: left !important;
    }
  }
`;

export default staticStyles;

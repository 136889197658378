/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers';
import { Provider } from '@ethersproject/providers';
import type {
  IUiPoolLimitDataProvider,
  IUiPoolLimitDataProviderInterface,
} from '../IUiPoolLimitDataProvider';

const _abi = [
  {
    inputs: [
      {
        internalType: 'contract ILendingPoolAddressesProvider',
        name: 'provider',
        type: 'address',
      },
    ],
    name: 'getLimits',
    outputs: [
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'maxGlobalDepositSize',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxIndividualDepositSize',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'minIndividualDepositSize',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxGlobalBorrowSize',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxIndividualBorrowSize',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxBorrowBps',
            type: 'uint256',
          },
        ],
        internalType: 'struct DataTypes.ReserveLimits[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];

export class IUiPoolLimitDataProvider__factory {
  static readonly abi = _abi;
  static createInterface(): IUiPoolLimitDataProviderInterface {
    return new utils.Interface(_abi) as IUiPoolLimitDataProviderInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): IUiPoolLimitDataProvider {
    return new Contract(address, _abi, signerOrProvider) as IUiPoolLimitDataProvider;
  }
}

import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Timer, TokenIcon, useThemeContext } from '@aave/aave-ui-kit';

import { marketsData } from '../../../../helpers/config/markets-and-network-config';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import {
  ComputedReserveData,
  useDynamicPoolDataContext,
  useStaticPoolDataContext,
} from '../../../../libs/pool-data-provider';
import { useStakeDataContext } from '../../../../libs/pool-data-provider/hooks/use-stake-data-context';
import { defaultTheme } from '../../../../ui-config/theme';

import ScreenWrapper from '../../../../components/wrappers/ScreenWrapper';
import ContentWrapper from '../../../../components/wrappers/ContentWrapper';
import DefaultButton from '../../../../components/basic/DefaultButton';
import Link from '../../../../components/basic/Link';
import Row from '../../../../components/basic/Row';
import ValuePercent from '../../../../components/basic/ValuePercent';
import NoDataPanel from '../../../../components/NoDataPanel';
import StakingTopPanel from '../StakingTopPanel';
import SidePanelCard from '../SidePanelCard';
import Value from '../../../../components/basic/Value';

import messages from './messages';
import staticStyles from './style';
import {
  LifetimeStakingRewardQuery,
  useLifetimeStakingRewardQuery,
} from '../../../../libs/pool-data-provider/graphql';
import { StakeRewardData } from '../../../../libs/pool-data-provider/types/stake';
import { normalize, valueToBigNumber } from '@aave/protocol-js';
import { assetsOrder } from '../../../../ui-config/assets';

interface StakingWrapperProps {
  children: ReactNode;
}

function getLifetimeRewards(
  rawLifetimeRewards: LifetimeStakingRewardQuery | undefined,
  reserves: ComputedReserveData[],
  marketRefPriceInUsd: string,
  phuxAddress?: string,
  phuxUsdPrice?: string
) {
  let userRewards: StakeRewardData[] = [];
  let userTotalRewardsInRef = valueToBigNumber(0);

  if (rawLifetimeRewards && rawLifetimeRewards.userRewardPaids.length > 0 && reserves.length > 0) {
    rawLifetimeRewards.userRewardPaids.forEach((record) => {
      if (
        phuxAddress &&
        phuxUsdPrice &&
        phuxUsdPrice !== '0' &&
        record.rewardToken === phuxAddress?.toLowerCase()
      ) {
        const phuxAmount = normalize(record.lifetimeRewards, 18);
        userRewards.push({
          tokenSymbol: 'PHUX',
          amount: phuxAmount,
        });
        const phuxInUsd = valueToBigNumber(phuxAmount)
          .multipliedBy(phuxUsdPrice)
          .dividedBy(marketRefPriceInUsd);
        userTotalRewardsInRef = userTotalRewardsInRef.plus(phuxInUsd);
        return;
      }
      const reserve = reserves.find(
        (reserve) => reserve.aTokenAddress.toLowerCase() === record.rewardToken
      );
      if (reserve) {
        const normalisedAmount = normalize(record.lifetimeRewards, reserve.decimals);
        userRewards.push({
          tokenSymbol: reserve.symbol,
          amount: normalisedAmount,
        });
        userTotalRewardsInRef = userTotalRewardsInRef.plus(
          valueToBigNumber(normalisedAmount).multipliedBy(reserve.priceInMarketReferenceCurrency)
        );
      }
    });
    userRewards = userRewards.sort(
      (a, b) =>
        assetsOrder.indexOf(a.tokenSymbol.toUpperCase()) -
        assetsOrder.indexOf(b.tokenSymbol.toUpperCase())
    );
  }

  return {
    rewards: userRewards,
    totalRewardsInUsd: userTotalRewardsInRef.multipliedBy(marketRefPriceInUsd),
  };
}

export default function StakingWrapper({ children }: StakingWrapperProps) {
  const intl = useIntl();
  const location = useLocation();
  const { currentTheme } = useThemeContext();
  const { currentMarket, networkConfig } = useProtocolDataContext();
  const { userId, marketRefPriceInUsd } = useStaticPoolDataContext();
  const { reserves } = useDynamicPoolDataContext();
  const { stakeConfig, stakeData, refresh } = useStakeDataContext();

  const {
    data: rawLifetimeRewards,
    loading,
    error,
  } = networkConfig.protocolDataUrl === ''
    ? {
        data: undefined,
        loading: false,
        error: true,
      }
    : useLifetimeStakingRewardQuery({
        skip: !userId,
        pollInterval: 60 * 1000,
        variables: {
          id: userId?.toLowerCase() || '',
        },
      });
  const lifetimeRewards = getLifetimeRewards(
    rawLifetimeRewards,
    reserves,
    marketRefPriceInUsd,
    stakeConfig.phuxToken,
    stakeData.phuxUsdPrice
  );

  return (
    <ScreenWrapper
      className="StakingWrapper"
      pageTitle={intl.formatMessage(messages.pageTitle)}
      isTopLineSmall={true}
    >
      <StakingTopPanel
        title={intl.formatMessage(messages.pageTitle)}
        totalSupply={stakeData.totalSupply}
        totalStakedSupply={stakeData.totalStakedSupply}
        totalStakedPercentage={stakeData.totalStakedPercentage}
      />

      <div className="StakingWrapper__content-inner">
        <ContentWrapper
          withBackButton={true}
          className={classNames('StakingWrapper__content-left', {
            StakingWrapper__contentLeftActive: false,
          })}
        >
          {!userId ? (
            <NoDataPanel
              title={intl.formatMessage(messages.noWalletConnect)}
              description={intl.formatMessage(messages.noWalletConnectDescription)}
              withConnectButton={true}
            />
          ) : (
            children
          )}
        </ContentWrapper>

        <div
          className={classNames('StakingWrapper__content-right', {
            StakingWrapper__contentRightActive: false,
          })}
        >
          <div className="StakingWrapper__cards-inner">
            <SidePanelCard
              title={intl.formatMessage(messages.staked, {
                symbol: <strong>{stakeConfig.stakingTokenSymbol}</strong>,
              })}
              value={stakeData.userStakedBalance}
              withGradientBorder={true}
            >
              {userId && (
                <div className="StakingWrapper__info-timerWrapper">
                  <div className="StakingWrapper__info-seaCreatureInner">
                    <p>
                      <span className="StakingWrapper__info-seaCreatureIcon">
                        {stakeData.userSeaCreature.icon}
                      </span>
                      <span className="StakingWrapper__info-seaCreatureText">
                        {stakeData.userSeaCreature.name}
                      </span>
                    </p>
                  </div>
                  <p className="StakingWrapper__info-timerText">
                    {stakeData.userStakedPct ? stakeData.userStakedPct + ' %' : <>&nbsp;</>}
                  </p>
                </div>
              )}
              <Link
                to={'/staking/unstake'}
                className="StakingWrapper__link ButtonLink"
                disabled={
                  location.pathname === '/staking/unstake' ||
                  location.pathname === '/staking/unstake/confirmation' ||
                  !stakeData.userCanUnstake
                }
              >
                <DefaultButton
                  title={intl.formatMessage(messages.unstake)}
                  className="StakingWrapper__button"
                  color="dark"
                  disabled={
                    location.pathname === '/staking/unstake' ||
                    location.pathname === '/staking/unstake/confirmation' ||
                    !stakeData.userCanUnstake
                  }
                />
              </Link>
            </SidePanelCard>

            {stakeData.userCanCancel && (
              <SidePanelCard
                title={intl.formatMessage(messages.unlocking, {
                  symbol: <strong>{stakeConfig.stakingTokenSymbol}</strong>,
                })}
                value={stakeData.userUnstakedBalance}
                withGradientBorder={false}
              >
                <div className="StakingWrapper__info-timerWrapper">
                  <div className="StakingWrapper__info-timerInner">
                    <Timer
                      className="StakingWrapper__timer"
                      dateInFuture={stakeData.userWithdrawTimestamp}
                      onComplete={refresh}
                    />
                  </div>
                  <p className="StakingWrapper__info-timerText">
                    {intl.formatMessage(messages.tillWithdraw)}
                  </p>
                </div>
                <Link
                  to={'/staking/cancel/confirmation'}
                  className="StakingWrapper__link ButtonLink"
                  disabled={
                    location.pathname === '/staking/cancel/confirmation' || !stakeData.userCanCancel
                  }
                >
                  <DefaultButton
                    title={intl.formatMessage(messages.cancel)}
                    className="StakingWrapper__button"
                    color="dark"
                    disabled={
                      location.pathname === '/staking/cancel/confirmation' ||
                      !stakeData.userCanCancel
                    }
                  />
                </Link>
              </SidePanelCard>
            )}

            {stakeData.userCanWithdraw && (
              <SidePanelCard
                title={intl.formatMessage(messages.withdrawable, {
                  symbol: <strong>{stakeConfig.stakingTokenSymbol}</strong>,
                })}
                value={stakeData.userWithdrawableBalance}
                withGradientBorder={false}
              >
                <div className="StakingWrapper__info-timerWrapper">
                  <div className="StakingWrapper__info-timerInner">
                    <Timer
                      className="StakingWrapper__timer"
                      dateInFuture={stakeData.userExpirationTimestamp}
                      onComplete={refresh}
                    />
                  </div>
                  <p className="StakingWrapper__info-timerText">
                    {intl.formatMessage(messages.leftToWithdraw)}
                  </p>
                </div>
                <Link
                  to={'/staking/withdraw/confirmation'}
                  className="StakingWrapper__link ButtonLink"
                  disabled={
                    location.pathname === '/staking/withdraw/confirmation' ||
                    !stakeData.userCanWithdraw
                  }
                >
                  <DefaultButton
                    title={intl.formatMessage(messages.withdraw)}
                    className="StakingWrapper__button"
                    color="dark"
                    disabled={
                      location.pathname === '/staking/withdraw/confirmation' ||
                      !stakeData.userCanWithdraw
                    }
                  />
                </Link>
              </SidePanelCard>
            )}
          </div>
          <Row
            title={intl.formatMessage(messages.unlockingPeriod)}
            className="StakingWrapper__row"
            weight="light"
          >
            <strong className="StakingWrapper__cooldownPeriodTime">
              {intl.formatNumber(stakeData.stakeUnlockDays)}{' '}
              <span>{intl.formatMessage(messages.days)}</span>
            </strong>
          </Row>
          <Row
            title={intl.formatMessage(messages.withdrawWindow)}
            className="StakingWrapper__row"
            weight="light"
          >
            <strong className="StakingWrapper__cooldownPeriodTime">
              {intl.formatNumber(stakeData.stakeWithdrawDays)}{' '}
              <span>{intl.formatMessage(messages.days)}</span>
            </strong>
          </Row>
          <Row
            title={intl.formatMessage(messages.stakingAPY)}
            className="StakingWrapper__row"
            weight="light"
          >
            <ValuePercent value={stakeData.stakeAPR} />
          </Row>
          {!loading && !error && lifetimeRewards.totalRewardsInUsd.gt(0) && (
            <div className="StakingWrapper__claimDiv">
              <Row
                title={intl.formatMessage(messages.totalRewards)}
                className="StakingWrapper__row"
              >
                <Value
                  symbol={intl.formatMessage(messages.usd)}
                  value={lifetimeRewards.totalRewardsInUsd.toNumber()}
                  maximumValueDecimals={2}
                  tokenIcon={false}
                  tooltipId={intl.formatMessage(messages.usd)}
                  className="StakingWrapper__claimValue"
                />
              </Row>
            </div>
          )}
          {!loading &&
            !error &&
            lifetimeRewards.rewards.length > 0 &&
            lifetimeRewards.rewards.map(({ tokenSymbol, amount }, index) => (
              <Row
                title={
                  <TokenIcon
                    tokenSymbol={tokenSymbol}
                    height={20}
                    width={20}
                    tokenFullName={`${
                      tokenSymbol === 'PHUX' ? '' : marketsData[currentMarket].aTokenPrefix
                    }${tokenSymbol}`}
                    key={index}
                  />
                }
                className="StakingWrapper__rewardRow"
                key={index}
              >
                <Value symbol={''} value={amount} tokenIcon={false} key={index} />
              </Row>
            ))}
          {stakeData.userCanClaim && (
            <div className="StakingWrapper__claimDiv">
              <Row
                title={
                  <Link
                    to={'/staking/claim/confirmation'}
                    className="StakingWrapper__link ButtonLink"
                    disabled={
                      location.pathname === '/staking/claim/confirmation' || !stakeData.userCanClaim
                    }
                  >
                    <DefaultButton
                      title={intl.formatMessage(messages.claim)}
                      className="StakingWrapper__claimButton"
                      color="dark"
                      disabled={
                        location.pathname === '/staking/claim/confirmation' ||
                        !stakeData.userCanClaim
                      }
                    />
                  </Link>
                }
                className="StakingWrapper__row"
                weight="light"
              >
                <Value
                  symbol={intl.formatMessage(messages.usd)}
                  value={stakeData.userTotalRewardsInUsd}
                  maximumValueDecimals={2}
                  tokenIcon={false}
                  tooltipId={intl.formatMessage(messages.usd)}
                  className="StakingWrapper__claimValue"
                />
              </Row>
            </div>
          )}
          {stakeData.userCanClaim &&
            !(
              !loading &&
              !error &&
              lifetimeRewards.totalRewardsInUsd.gt(0) &&
              lifetimeRewards.rewards.length > 0
            ) &&
            stakeData.userRewards.map(({ tokenSymbol, amount }, index) => (
              <Row
                title={
                  <TokenIcon
                    tokenSymbol={tokenSymbol}
                    height={20}
                    width={20}
                    tokenFullName={`${
                      tokenSymbol === 'PHUX' ? '' : marketsData[currentMarket].aTokenPrefix
                    }${tokenSymbol}`}
                    key={index}
                  />
                }
                className="StakingWrapper__rewardRow"
                key={index}
              >
                <Value symbol={''} value={amount} tokenIcon={false} key={index} />
              </Row>
            ))}
        </div>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        @import 'src/_mixins/screen-size';

        .StakingWrapper {
          &__content-left,
          &__content-right {
            background: ${currentTheme.whiteElement.hex};
          }

          &__info-timerWrapper {
            color: ${currentTheme.textDarkBlue.hex};
          }
          &__info-timerInner {
            background: ${currentTheme.mainBg.hex};
            @include respond-to(sm) {
              background: ${currentTheme.disabledGray.hex};
            }
            .StakingWrapper__timer {
              color: ${currentTheme.textDarkBlue.hex};
            }
          }

          &__info-seaCreatureInner {
            background: ${currentTheme.mainBg.hex};
            @include respond-to(sm) {
              background: ${currentTheme.disabledGray.hex};
            }
          }
          &__info-seaCreatureText {
            background: ${defaultTheme.rotatingGradientBackground};
            background-size: 200% auto;
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: shine 4.5s linear infinite;
            @keyframes shine {
              to {
                background-position: 200% center;
              }
            }
          }

          &__claimButton {
            &:before,
            &:after {
              background: ${defaultTheme.rotatingGradientBackground};
            }

            &:disabled {
              &:before,
              &:after {
                background: ${currentTheme.mainBg.hex} !important;
                border-color: ${currentTheme.mainBg.hex} !important;
              }
            }

            color: ${currentTheme.textDarkBlue.hex};
          }
        }
      `}</style>
    </ScreenWrapper>
  );
}

import { Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  transactionType,
} from '@aave/contract-helpers';

import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { useStakeDataContext } from '../../../../libs/pool-data-provider/hooks/use-stake-data-context';
import { getProvider } from '../../../../helpers/config/markets-and-network-config';
import { PhiatFeeDistribution__factory } from '../../../../libs/pool-data-provider/contracts/factories/PhiatFeeDistribution__factory';

import Row from '../../../../components/basic/Row';
import Value from '../../../../components/basic/Value';
import StakeTxConfirmationView from '../../components/StakeTxConfirmationView';
import messages from './messages';

export default function WithdrawConfirmation() {
  const intl = useIntl();
  const { userId } = useStaticPoolDataContext();
  const { chainId, stakeConfig, stakeData, stakingService } = useStakeDataContext();

  if (!userId) {
    return null;
  }
  if (!stakeData.userCanWithdraw) {
    return <Redirect to="/staking" />;
  }

  const blockingError = '';

  const handleGetTransactions = async (): Promise<EthereumTransactionTypeExtended[]> => {
    const stakingContract = PhiatFeeDistribution__factory.connect(
      stakeConfig.feeDistribution,
      getProvider(chainId)
    );
    const txCallback: () => Promise<transactionType> = stakingService.generateTxCallback({
      rawTxMethod: async () => stakingContract.populateTransaction.withdraw(),
      from: userId,
      gasSurplus: 20,
    });
    return [
      {
        tx: txCallback,
        txType: eEthereumTxType.STAKE_ACTION,
        gas: stakingService.generateTxPriceEstimation([], txCallback),
      },
    ];
  };

  return (
    <StakeTxConfirmationView
      caption={intl.formatMessage(messages.title)}
      description={intl.formatMessage(messages.description)}
      getTransactionsData={handleGetTransactions}
      boxTitle={intl.formatMessage(messages.withdraw, {
        asset: stakeConfig.stakingTokenSymbol,
      })}
      boxDescription={intl.formatMessage(messages.boxDescription)}
      mainTxName={intl.formatMessage(messages.withdraw)}
      goToAfterSuccess="/staking"
      successButtonTitle={intl.formatMessage(messages.backToStaking)}
      blockingError={blockingError}
      buttonTitle={intl.formatMessage(messages.withdraw)}
    >
      <Row title={intl.formatMessage(messages.withdraw)}>
        <Value
          symbol={stakeConfig.stakingTokenSymbol}
          value={stakeData.userWithdrawableBalance}
          tokenIcon={true}
          tooltipId={stakeConfig.stakingTokenSymbol}
        />
      </Row>
    </StakeTxConfirmationView>
  );
}

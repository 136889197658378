import { defineMessages } from 'react-intl';

export default defineMessages({
  amountTitle: { defaultMessage: 'Available to repay' },
  formDescription: { defaultMessage: 'How much do you want to repay?' },

  error: { defaultMessage: 'Incorrect connection to HOC' },

  warningText: {
    defaultMessage:
      'Before repaying {symbol} please check the amount you want to repay is not used for staking. If it is used for staking, your transaction might fail.',
  },
});
